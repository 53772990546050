import styled from 'styled-components';

export const Container = styled.div`
  .please-read-div{
    background-color: #ECECF3;
    border-radius: 8px;
    padding: 15px;
  
    .please-read-header{
      align-items: center!important;
      justify-content: center!important;

      label{
          text-align: left;
          font: normal normal bold 16px/16px Montserrat;
          letter-spacing: 0px;
          color: #387722;
          text-shadow: 0px 3px 20px #0000000D;
          align-items: center!important;
          justify-content: center!important;
          margin-left: 15px;
      }
    
      svg{
          color: #387722!important;
          cursor: default;
      }
    
      .svg-circled{
          float: right;
          background-color: #FFFFFF;
          padding: 10px;
          border-radius: 100%;
          cursor: pointer;
    
          &:hover{
              background-color: #387722;
              color: #FFFFFF!important;
          }
      } 
    }
    
  }
`;
