import React, { useEffect, useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import Api, { exceptionNotificationAPI } from '../../services/api';
import FormCrud from '../../components/FormCrud';
import { Container } from './styles';
import { Form } from '@unform/web';
import FormInput from '../../components/FormInput';
import Button from '../../components/Button';
import CancelButton from '../../components/CancelButton';
import { AiOutlineDelete } from 'react-icons/ai'
import { success_message } from '../../components/Toast/index.jsx';
import getValidationErrors from '../../utils/getValidationErrors';
import makeAnimated from 'react-select/animated';
import Select, { ActionMeta, OnChangeValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { element } from 'prop-types';
import ModalCustomInput from '../../components/ModalCustomInput';


const SubSectionForm = () => {
    const [sectionOptions, setSectionOptions] = useState();
    const [sections, setSections] = useState();
    const [companiesOptions, setCompaniesOptions] = useState();
    const [reportOptions, setReportOptions] = useState();
    const [parentColunsList, setParentColunsList] = useState();
    const history = useHistory();
    const { id } = useParams();
    const formRef = useRef(null);
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isColumnsLoading, setIsColumnsLoading] = useState(false);
    const [isColumnRow, setIsColumnRow] = useState(false);
    const [selectColumnRow, setSelectColumnRow] = useState(true);
    const [multipleValues, setMultipleValues] = useState([]);
    const [selectedReportYearID, setSelectedReportYearID] = useState();

    const [modalCustomInputObject, setModalCustomInputObject] = useState();
    const [modalCustomInputSelectedValues, setModalCustomInputSelectedValues] = useState([]);
    const [showModalCustomInput, setShowModalCustomInput] = useState(false);
    const [referenceColIndex, setReferenceColIndex] = useState();
    const [referenceIndex, setReferenceIndex] = useState();

    const activeOptions = [
        { value: true, text: "Yes" },
        { value: false, text: "No" }
    ];

    const typeOptions = [
        { value: 1, text: "Simple" },
        { value: 2, text: "Country" },
        { value: 3, text: "FuelType" },
        { value: 4, text: "TobaccoType" },
        { value: 5, text: "Year" }
    ];

    const quantQualOptions = [
        { value: false, text: "Column" },
        { value: true, text: "Row" }
    ];


    const loadData = async () => {
        try {
            const result = await Api.get('Section/getAll')
            if (result && result.data) {
                const options = result.data.response.map(item => ({ value: item.sectionID, text: `${item.description}`, reportYearID: item.reportYearID }));
                setSectionOptions(options);
                setSections(options);

            }
        } catch (error) {
            exceptionNotificationAPI(error);
        };
    }

    const loadCompanies = async () => {
        try {
            const result = await Api.get('Company/getAll')
            if (result && result.data) {
                const options = result.data.response.map(item => ({ value: item.companyID, label: `${item.name}` }));

                setCompaniesOptions(options);
            }
        } catch (error) {
            exceptionNotificationAPI(error);
        };
    }

    const loadReportYear = async () => {
        try {
            const result = await Api.get('ReportYear/getAll')
            if (result && result.data) {
                const options = result.data.response.map(item => ({ value: item.reportYearID, text: `${item.year}` }));
                if (!id) {
                    setSelectedReportYearID(options[0].value)
                }
                setReportOptions(options);
            }

        } catch (error) {
            exceptionNotificationAPI(error);
        };
    }

    useEffect(() => {
        async function load() {
            await loadData();
            await loadCompanies();
            await loadReportYear();
            //filterSections()
            setIsLoading(false)
        }

        load()



    }, []);

    useEffect(() => {
        if (!isColumnsLoading) {
            if (sections != null && selectedReportYearID) {
                var filteredModules = sections.filter(x => x.reportYearID == selectedReportYearID);
                const options = filteredModules;

                setSectionOptions(options);
            }
        }
    }, [selectedReportYearID]);


    useEffect(() => {
        if (data === null && id != null && !isLoading) {
            console.log(sectionOptions)
            loadDataEdit();
        }

    }, [isLoading]);


    const loadDataEdit = async () => {
        if (!isLoading) {
            setIsColumnsLoading(true);
            await Api.get(`subSection/get?id=${id}`).then((result) => {
                if (result && result.data) {
                    var dataObj = {};
                    var companyOptions = [];
                    var groupOptions = [];

                    Sectionfields.forEach(f => {
                        if (f.name == "subSectionCompany" && result.data.response["subSectionCompany"] != null) {
                            var dataMultiple = result.data.response["subSectionCompany"].map((sc) => {
                                var data = {
                                    value: sc.companyID,
                                    label: sc.company.name
                                };
                                return data;
                            });
                            setMultipleValues(dataMultiple);
                        }
                        else if (f.name.indexOf(".") > -1) {
                            var fragments = f.name.split('.');

                            var object = getObjects(result.data.response, fragments);

                            dataObj[f.name] = (object != null ? object : "");
                        }

                        else {
                            dataObj[f.name] = result.data.response[f.name];
                        }

                    });


                    dataObj["ColumnRow"] = result.data.response.columnRows;


                    var list = [];
                    list = result.data.response.columnRows.filter(x => x.isParentColumn == true);

                    var options = [{ value: 0, text: "None" }];

                    list.map((item, i) => (
                        options.push({ value: item.columnRowOrder, text: `${item.name}` })
                    ));

                    setParentColunsList(options);

                    setColumnsRow({ ...columnsRow, column: result.data.response.columnRows });

                    if (sections != null && dataObj.reportYearID) {
                        var filteredModules = sections.filter(x => x.reportYearID == dataObj.reportYearID);
                        const options = filteredModules;

                        setSectionOptions(options);
                    }


                    setSelectedReportYearID(dataObj.reportYearID)


                    if (dataObj != null) {
                        formRef.current.setData(dataObj);
                        setData(dataObj);
                    }

                    setIsColumnsLoading(false);
                    // setIsColumnRow(true)
                }
            }).catch((error) => {
                exceptionNotificationAPI(error);
            });
        }

    }

    const getObjects = (currentObject, fragments) => {
        if (fragments.length > 0) {
            var object = currentObject[fragments[0]];

            if (object != null) {
                fragments.shift();
                return getObjects(object, fragments);
            }
            else {
                return "";
            }
        }

        return currentObject;
    }




    const handleSubmit = async (data, e) => {
        try {
            var obj = {}
            var postObj = {};
            const submitUrl = `SubSection/${(id != null) ? "edit" : "new"}`;

            postObj["subSectionID"] = id;

            Sectionfields.map(f => {
                if (f.required !== undefined && f.required === false) {
                    return null;
                } else {
                    return obj[f.name] = Yup.string().required(`${f.label} is required`);
                }

            })

            const schema = Yup.object().shape(obj);

            await schema.validate(data, {
                abortEarly: false,
            });

            Sectionfields.map(f => {
                return postObj[f.name] = data[f.name]
            })

            postObj["ColumnRows"] = columnsRow.column;

            if (multipleValues.length > 0) {
                postObj["subSectionCompany"] = multipleValues.map((m) => {
                    var data = {
                        companyID: m.value
                    }
                    return data;
                });
            }

            await Api.post(submitUrl, postObj).then((result) => {
                if (result && result.data) {
                    success_message("Saved successfuly");
                    history.goBack();
                }
            });

        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const errors = getValidationErrors(error);

                formRef.current.setErrors(errors);

                return;
            }

            exceptionNotificationAPI(error);
        }
    }

    const handleLoadColumnRowInputs = (value) => {
        var length = columnsRow.column.length;

        var newColumn = {
            name: null,
            columnRowOrder: length + 1,
            type: 1,
            kpiReference: null,
            isParentColumn: false,
            parentColumnRowID: null,
            minimumQuantity: null,
            subSectionID: id
        }

        columnsRow.column.push(newColumn);

        //columnsRow.column = columnsRow.column.sort((a,b) => a.columnRowOrder - b.columnRowOrder);
        setColumnsRow({ ...columnsRow, column: columnsRow.column });
    }

    const handleRemoveColumnRowInputs = (value, i) => {
        var columns = columnsRow.column;

        var excludeColumn = columns[i];

        columns.splice(i, 1)

       var updatedColumns =  columns.map((col) => {
            if(col.parentColumnRowID == excludeColumn.columnRowOrder) {
                col.parentColumnRowID = null;
            }

            return col;
        })

        setColumnsRow({ ...columnsRow, column: updatedColumns })


    }



    useEffect(() => {
        if (columnsRow.column !== null || columnsRow.column.length > 0) {
            var list = [];
            list = columnsRow.column.filter(x => x.isParentColumn == true);

            var options = [{ value: null, text: "None" }];

            list.map((item, i) => (
                options.push({ value: item.columnRowOrder, text: `${item.name}` })
            ));

            setParentColunsList(options);
            setIsColumnRow(false)
        }
    }, [isColumnRow]);

    const setColumn = (col, colIndex, index, value, label) => {
        var myCol = columnsRow.column[colIndex];

        if (myCol != null) {
            if (value != null && index == 0)
                myCol.name = value;
            if (value != null && index == 1)
                myCol.columnRowOrder = value;
            if (value != null && index == 2)
                myCol.type = value;
            if (value != null && index == 3)
                myCol.kpiReference = value;
            if (value != null && index == 4)
                myCol.minimumQuantity = value;
            if (value != null && index == 5)
                myCol.isParentColumn = value;
            if (value != null && index == 6)
                myCol.parentColumnRowID = value;
            if (value != null && index == 7)
                myCol.columnRowCompany = value;
        }

        var myList = [];
        myList = columnsRow.column.filter(x => x != myCol);
        var myColIndex = columnsRow.column.findIndex(x => x == myCol);

        myList.splice(myColIndex, 0, myCol);

        //myList.sort((a, b) => a.columnRowOrder - b.columnRowOrder);

        setColumnsRow({ ...columnsRow, column: myList })
        setIsColumnRow(true);
    }



    const selectReportYear = async (e) => {
        setSelectedReportYearID(e);
    }

    const requestModalInput = (col, colind, ind) => {
        setReferenceColIndex(colind);
        setReferenceIndex(ind);
        setModalCustomInputObject(col);
        setModalCustomInputSelectedValues(col?.columnRowCompany);
        setShowModalCustomInput(true);

    }

    const updateColumnRowCompanies = (col, colind, ind, companiesToExclude) => {

        var dataCompanyToExclude = companiesToExclude.map((ce) => {
            var data = {
                value: ce.value,
                label: ce.label,
                companyID: ce.value,
                columnRowID: col.columnRowID
            };
            return data;
        });
        var auxList = columnsRow.column;
        var relatedColumns = col.isParentColumn ? auxList.filter(x => x.parentColumnRowID == col.columnRowOrder) : null;
        if(!relatedColumns){
            var childColumns = auxList.filter(x => x.parentColumnRowID == col.parentColumnRowID)
            if(childColumns.length == 1){
                relatedColumns = auxList.filter(x => x.columnRowOrder == col.parentColumnRowID);
            }
        }
        setColumn(col, colind, ind, dataCompanyToExclude, col.label);

        if(relatedColumns){
            relatedColumns.forEach(function(currentValue, index) {
                // code to be executed for each element
                var indexOf = auxList.indexOf(currentValue);
                if(indexOf != null){
                    dataCompanyToExclude.forEach(function(data){
                        data.columnRowID = currentValue.columnRowID;
                    });
                    setColumn(currentValue, indexOf, ind, dataCompanyToExclude, currentValue.label);
                }
            });
        }

        setShowModalCustomInput(false);
    }


    const Sectionfields = [
        {
            label: "Description",
            name: "description",
            type: "input"
        },
        {
            label: "Report Year",
            name: "reportYearID",
            type: "select",
            options: reportOptions,
            onChanged: selectReportYear
        },
        {
            label: "Section",
            name: "sectionID",
            type: "select",
            options: sectionOptions
        },
        {
            label: "Order",
            name: "order",
            type: "number"
        },
        {
            label: "Companies to exclude",
            name: "subSectionCompany",
            type: "customOptions",
            required: false,
            options: companiesOptions,
            multiple: true
        },
        {
            label: "Guidance",
            name: "guidance",
            type: "textarea",
            required: false
        },
        {
            label: "Column/Row",
            name: "isColumnRow",
            type: "select",
            options: quantQualOptions,
            required: true,
        },
        {
            label: "Is By Tobacco Type?",
            name: "isTobaccoType",
            type: "select",
            options: activeOptions
        },
        {
            label: "Is Not Show Add/Delete Buttons?",
            name: "isNotShowAddDeleteButtons",
            type: "select",
            options: activeOptions
        },
        {
            label: "Is Active",
            name: "isActive",
            type: "select",
            options: activeOptions
        },
        {
            label: "Is Action Plan",
            name: "isActionPlan",
            type: "select",
            options: activeOptions
        },
    ]

    const [columnsRow, setColumnsRow] = useState({
        column: [{
            "name": " ",
            "columnRowOrder": 1,
            "sectionID": id,
            "type": 1,
            "kpiReference": null,
            "minimumQuantity": null,
            "isParentColumn": false,
            "parentColumnRowID": null,
            "columnRowCompany": [],
        }]
    });

    const ColumnRowfields = [

        {
            label: "Name",
            name: "name",
            type: "input",
            required: true
        },
        {
            label: "Order",
            name: "columnRowOrder",
            type: "number",
            required: true,
            disabled: false,
        },
        {
            label: "Type",
            name: "type",
            type: "select",
            options: typeOptions,
        },
        {
            label: "KPI Reference",
            name: "kpiReference",
            type: "input",
            required: true
        },
        {
            label: "Minimum Quantity",
            name: "minimumQuantity",
            type: "number",
            required: false
        },
        {
            label: "Is a parent Column",
            name: "isParentColumn",
            type: "check",
            required: false
        },
        {
            label: "Parent Column",
            name: "parentColumnRowID",
            type: "select",
            options: parentColunsList,
        },
        {
            label: "Companies to exclude",
            name: "columnRowCompany",
            type: "requestModalInput",
            required: false
        },

    ]


    return (
        !isLoading ?
            <Container className="container">
                <div>
                    <h3>{(id != null) ? "Edit" : "New"} SubSection</h3>
                    <Form onSubmit={handleSubmit} ref={formRef}>
                        <table className="table table-striped" style={isColumnsLoading && isLoading ? { visibility: 'hidden' } : { visibility: 'visible' }}>
                            <tbody>
                                {
                                    Sectionfields.map((f, i) => {
                                        if (id !== undefined && f.editable !== undefined && f.editable === false) {
                                            return <></>
                                        }
                                        else {
                                            return (<>
                                                <tr key={i + "Tr"} id={f.name + "Tr"}>
                                                    <td width="25%">
                                                        <label>
                                                            {`${f.label}`}  {f.required === undefined || f.required === true ? <span className="required">*</span> : <></>}
                                                        </label>
                                                    </td>
                                                    <td width="75%">
                                                        {f.type == "customOptions" ?
                                                            <Select
                                                                options={companiesOptions}
                                                                value={multipleValues}
                                                                isMulti
                                                                closeMenuOnSelect={false}
                                                                onChange={(a) => setMultipleValues(a)}
                                                            />
                                                            :
                                                            <FormInput
                                                                className="form-control"
                                                                name={f.name}
                                                                id={f.name}
                                                                disabled={f.disabled ? f.disabled : false}
                                                                checked={f.checked}
                                                                inputType={f.type}
                                                                options={f.options}
                                                                onChange={(e) => (f.onChanged != null) ? f.onChanged(e.target.value) : null} />
                                                        }

                                                    </td>
                                                </tr>

                                            </>)
                                        }
                                    })


                                }
                            </tbody>
                        </table>
                        {/* {selectColumnRow == true  ? */}
                        <div className='mt-5'>
                            <a className="addnewLine btn-primary" style={{ padding: '0.5rem 1rem', textDecoration: 'none', cursor: 'pointer' }} onClick={() => handleLoadColumnRowInputs()}>Add New Column</a>
                        </div>
                        {
                            !isColumnsLoading ?
                                <table className="table table-striped my-5">
                                    <thead>
                                        <tr><th scope="col">{"Index"}</th>
                                            {ColumnRowfields.map((cs) => {
                                                return (
                                                    <th width={cs.name == "name" ? "20%" : ""} scope="col text-center">{cs.label}</th>
                                                )
                                            })}
                                            <th scope="col">{""}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {columnsRow.column.map((col, colinx) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        <label>
                                                            {colinx + 1}
                                                        </label>
                                                    </td>
                                                    {ColumnRowfields.map((c, ind) => {
                                                        return (
                                                            ind == 0 ?
                                                                <td scope="row">
                                                                    <FormInput
                                                                        className="form-control"
                                                                        name={c.name}
                                                                        id={`col-${colinx}-${c.name}`}
                                                                        disabled={c.disabled ? c.disabled : false}
                                                                        checked={c.checked}
                                                                        inputType={c.type}
                                                                        options={c.options}
                                                                        value={col[c.name]}
                                                                        onChange={(e) => setColumn(col, colinx, ind, e.target.value, c.label)}
                                                                    />
                                                                </td>
                                                                :
                                                                c.type == "requestModalInput" ?
                                                                <td>
                                                                    <button type="button" onClick={(e) => requestModalInput(col, colinx, ind)}>ADD</button>
                                                                </td>
                                                                :
                                                                <td style={c.type == "number" ? { width: "7rem" } : c.type == "select" ? { width: "17rem" } : { width: "10rem" }}>
                                                                    <FormInput
                                                                        className="form-control"
                                                                        name={c.name}
                                                                        id={`col-${colinx}-${c.name}`}
                                                                        disabled={c.disabled ? c.disabled : false}
                                                                        checked={col[c.name]}
                                                                        inputType={c.type}
                                                                        options={c.options}
                                                                        value={col[c.name]}
                                                                        onChange={(e) => setColumn(col, colinx, ind, c.type == "check" ? e.target.checked : e.target.value)}
                                                                    />
                                                                </td>
                                                        )
                                                    })}
                                                    {colinx != 0 ? <td><AiOutlineDelete size={20} onClick={(e) => handleRemoveColumnRowInputs(e.target.value, colinx)} /></td> : <></>}
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                :
                                <></>
                        }


                        <div className="d-flex flex-row mt-2">
                            <CancelButton />
                            <Button type="submit">Save</Button>
                        </div>
                    </Form>

                </div>
                <ModalCustomInput
                    referenceObject={modalCustomInputObject}
                    referenceColIndex={referenceColIndex}
                    referenceIndex={referenceIndex}
                    selectedValues={modalCustomInputSelectedValues}
                    options={companiesOptions}
                    isMultipleSelect={true}
                    showModalCustomInput={showModalCustomInput}
                    setShowModalCustomInput={setShowModalCustomInput}
                    requestName={"Companies to Exclude"}
                    requestDescription={"Please select the companies to be excluded"}
                    onChangeEvent={updateColumnRowCompanies}
                />
            </Container>
            : <></>
    );





}

export default SubSectionForm;


