import React, { useMemo } from 'react';
import logo from '../../assets/images/logo.jpg';
import logoThrive from '../../assets/images/logoLQG360.png';
import { useHistory } from 'react-router-dom';
import {
    Container,
    SidebarHeader,
    SidebarBody,
    SidebarFooter
} from './style';
import { FiX } from 'react-icons/fi';
import { FaChevronRight, FaChevronDown } from 'react-icons/fa';
import { useAuth } from '../../hooks/auth';

import RoutesList from '../../routes/routesList';

const Sidebar = ({ active, setActive, openMenu, setOpenMenu }) => {
    const history = useHistory();
    const { user, userRoles } = useAuth();

    const sidebarMenuItems = useMemo(() => {       
        return RoutesList.filter(item => item.showInSidebar && (item.allowedRoles == null || item.allowedRoles?.some(role => userRoles?.includes(role))) && (!item?.onlyGlobal || (item?.onlyGlobal && user?.companyID == null) || item.justInfoHolder));
    }, [userRoles]);

    return (
        <Container className={(active ? "show" : "hide")}>
            <SidebarHeader>
                <div>
                    <FiX onClick={() => { setActive(!active) }} alt="Close menu" size={20} />
                </div>
                <div className='li-logo'>
                    <img src={logoThrive} alt="Logo Thrive Digital" style={{width: '100%'}} />
                </div>
            </SidebarHeader>
            <SidebarBody>
                {active && <ul>
                    {sidebarMenuItems.map(item => (
                        <>
                            <li key={item.label} style={{ position: "relative", borderBottom: "1px solid #BDC7DA25" }} onClick={() => {
                                if (item.isMenu) {
                                    localStorage.setItem('@QualityApp:openMenu', openMenu == item.label ? null : item.label);
                                    setOpenMenu(openMenu == item.label ? null : item.label);
                                } else {
                                    setActive(false);
                                    history.push(item.path);
                                }
                            }}>
                                {item.isMenu && (<span style={{ position: "absolute", left: 12 }}>{openMenu == item.label ? <FaChevronDown size={14} /> : <FaChevronRight size={14} />}</span>)}
                                <label>
                                    {item.label}
                                </label>
                            </li>
                            {openMenu == item.label && item.childrens && <ul>
                                {item.childrens.filter(children => children.showInSidebar && (children.allowedRoles == null || children.allowedRoles?.some(role => userRoles?.includes(role))) && (!children?.onlyGlobal || (children?.onlyGlobal && user?.companyID == null))).map((children) => {
                                    return <li style={{ paddingLeft: 15, borderBottom: "1px solid #BDC7DA25" }} onClick={() => {
                                        setActive(false);
                                        history.push(children.path);
                                    }}>
                                        <label>{children.label}</label>
                                    </li>
                                })}
                            </ul>}
                        </>
                    ))}
                </ul>}
            </SidebarBody>
        </Container>
    );
}

export default Sidebar;
