import React, { useState } from 'react';
import TableCrud from '../../components/TableCrud';

import { Spreadsheet, createEmptyMatrix } from 'react-spreadsheet';
import { useEffect } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { IoMdOptions } from 'react-icons/io';
import { Si } from 'react-icons/si';
import Api, { exceptionNotificationAPI } from '../../services/api';
import { useParams } from 'react-router-dom';
import { Container, ContainerHeader } from './styles';
import Button from '../../components/Button';
import { success_message, info_message, error_message } from '../../components/Toast/index';
import TableLoading from '../../components/TableLoading';
import NumberFormat from 'react-number-format';
import Swal from 'sweetalert2';

import optionsLogo from '../../assets/icons/options.svg';




const MailNotificationDeadlineFormIndex = () => {
    const columns = ["Supplier", "Deadline", "Notify how many days before?", "Start sending?", "CC in 1st communication", " "]

    //Crop Year Select Variables
    const [cropYears, setCropYears] = useState([]);
    const [selectedCropID, setSelectedCropID] = useState(null);
    const [operations, setOperations] = useState([]);
    const [rowLabels, setRowLabels] = useState([]);
    const [selectedOperationID, setSelectedOperationID] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [iDsList, setIDsList] = useState([]);
    const [dataSheet, setDataSheet] = useState([
        [],
        [],
    ]);

    const removeRow = (id) => {
        Swal.fire({
            title: 'Are you sure, you want delete the register?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonColor: '#102A63',
            cancelButtonColor: '#B0B0BB',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                Api.post(`MailNotificationDeadlines/delete?id=${id}`).then((result) => {
                    if (selectedCropID && selectedOperationID) {
                        loadDataByCropYearOperation()
                    }
                })
            }
        })
    }

    const InputNumberEdit = ({cell}) => {
        var rowID = cell.className.split('_')[1];
        return (
            <div style={{ padding: '0.5rem 1rem', textAlign: "center" }} onClick={() => {removeRow(rowID)}}>
                <FaTrashAlt />
            </div>
        );
    };

    const InputOptions = ({ cell, onChange }) => {
        return (
            <div style={{ padding: '0.5rem 1rem' }}>
                <img src={optionsLogo} alt="" />
            </div>
        );
    };

    useEffect(() => {
        setIsLoading(true)
        loadCropYears()
        //loadOperations()
    }, [])

    useEffect(() => {
        if (selectedCropID) {
            setIsLoading(true)
            loadOperations()
        }        
    }, [selectedCropID])

    useEffect(() => {
        if (selectedCropID && selectedOperationID) {
            setIsLoading(true);
            loadDataByCropYearOperation()
        }
    }, [selectedCropID, selectedOperationID])

    const loadCropYears = () => {
        Api.get(`FormValue/getAllCropsYears`).then((result) => {
            if (result && result.data) {
                setCropYears(result.data.response);
                setSelectedCropID(result.data.response[0].cropID);
                setIsLoading(false)
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const onChangeCropID = (value) => {
        setSelectedCropID(value);
    }

    const loadOperations = () => {
        Api.get(`Dashboard/getAllOperationsByYear?reportYearID=${selectedCropID}`).then((result) => {
            if (result && result.data) {
                console.log(result.data.response[0].operationID);
                setOperations(result.data.response);

                setSelectedOperationID(result.data.response[0].operationID);
                setIsLoading(false)
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const onChangeOperationID = (value) => {
        setSelectedOperationID(value);
    }

    const loadDataByCropYearOperation = () => {
        if (selectedCropID && selectedOperationID) {
            Api.get(`MailNotificationDeadlines/getAllByReportYearAndCountry?reportYearID=${selectedCropID}&operationID=${selectedOperationID}`).then((result) => {
                if (result && result.data) {                    
                    setIDsList(result.data.response.iDs)
                    var resultList = [...result.data.response.data];
                    var newLine = [
                        { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" },  { value: "" },
                    ]
                    resultList.push(newLine)
                    //setIsLoading(false)

                    try {
                        var resultDatasheet = [];
                        var rowLabel = [];
                        resultDatasheet = resultList.map((row, i) => {
                            var res = [];
                            var id = i + 1;
                            row[5] = { readOnly: false, className: 'remove_' + row[5].value, onClick: () => removeRow(row[5].value) };
                            row[5].DataViewer = InputOptions;
                            row[5].DataEditor = InputNumberEdit;
                            rowLabel.push(id);
                            return res;
                        })
                        setRowLabels(rowLabel);
                        setDataSheet(resultList);
                        setIsLoading(false)
                    } catch (e) {
                        console.log(e)
                    }
                }
            }).catch((error) => {
                exceptionNotificationAPI(error);
            });
        }
    }

    const save = () => {
        var objAdditionalData = {
            data: dataSheet,
            IDs: iDsList,
            operationID: selectedOperationID,
            reportYearID: selectedCropID,
            
        }
        setIsLoading(true);
        //console.log(objAdditionalData)
        Api.post(`MailNotificationDeadlines/new`, objAdditionalData).then((result) => {
            if (result && result.data) {
                if (selectedCropID && selectedOperationID) {
                    loadDataByCropYearOperation()
                }
                setIsLoading(false);
                if (result.data.response == true) {
                    success_message("The data was saved!");
                }
                else {
                    info_message("No data to saved!");
                }
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const onChangeTable = (e) => {
        try {
            setDataSheet(e);
        } catch { }
    }

    const onBlurTable = () => {
        try {
            var nesw = dataSheet.map((row) => {
                var newRow = row.map((col) => {
                    var newCol = { ...col };
                    return newCol;
                })
                return newRow;
            })

            try {
                nesw.map((row, i) => {
                    var value1 = row[1].value != "" ? row[1].value : ""
                    var value2 = row[2].value != "" ? row[2].value : ""
                    var value3 = row[3].value != "" ? row[3].value : ""
                    var value4 = row[4].value != "" ? row[4].value : ""

                    row[1] = { value: value1.includes(" - ") || value1 == " " || value1 == "-" || value1 == null || value1 == undefined ? "" : value1 };
                    row[2] = { value: value2.includes(" - ") || value2 == " " || value2 == "-" || value2 == null || value2 == undefined ? "" : value2 };
                    row[3] = { value: value3.includes(" - ") || value3 == " " || value3 == "-" || value3 == null || value3 == undefined ? "" : value3 };
                    row[4] = { value: value4.includes(" - ") || value4 == " " || value4 == "-" || value4 == null || value4 == undefined ? "" : value4 };

                    row[5] = { readOnly: false, className: 'remove-' + row[0].value, onClick: () => {removeRow(row[0].value) }};
                    row[5].DataViewer = InputOptions;
                    row[5].DataEditor = InputNumberEdit;


                })

            } catch { }
            setDataSheet(nesw);
        } catch (e) {
            console.log(e)
        }
    }



    return (
        <Container className="container">
            <ContainerHeader style={{ margin: '2rem 0' }}>
                <h3>Email Notifications - Deadlines Form</h3>
                <div>
                    <Button style={{ marginRight: '15px' }} onClick={() => save()}>Save</Button>
                </div>
            </ContainerHeader>
            <div className="row mt-2">
                <div className="col-md-6 mt-2">
                    <label className='label'>Select Reporting Year:</label>
                    <select className="col-md-6 mx-4 mt-2" onChange={(x) => onChangeCropID(x.target.value)} value={selectedCropID ? selectedCropID : ""}>
                        {
                            cropYears ?
                                cropYears.map(cropYear => {
                                    return <option key={cropYear.year} value={cropYear.reportYearID}>{cropYear.year}</option>
                                })
                                :
                                <></>
                        }
                    </select>
                </div>
                <div className="col-md-6 mt-2">
                    <label className='label'>Select Operation:</label>

                    <select className="col-md-6 mx-4 mt-2" onChange={(x) => onChangeOperationID(x.target.value)} value={selectedOperationID ? selectedOperationID : ""}>
                        {
                            operations ?
                            operations.map(operation => {
                                    return <option key={operation.operationID} value={operation.operationID}>{operation.description}</option>
                                })
                                :
                                <></>
                        }
                    </select>

                </div>
            </div>
            {!isLoading ?
                <Spreadsheet data={dataSheet} rowLabels={rowLabels} columnLabels={columns} onBlur={() => onBlurTable()} onChange={(e) => onChangeTable(e)} />
                :
                <TableLoading />
            }
        </Container>
    )
}

export default MailNotificationDeadlineFormIndex;
