import { Component, useEffect, useState } from "react";
import Api, { exceptionNotificationAPI } from "./api";
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useAuth } from '../hooks/auth';
import { success_message, info_message, error_message } from '../components/Toast/index';
import { getAnswers, evaluate, calculation } from "./functions";

export const toleranceValidations = ({ subSectionKPI, column = null, row = null, value = null, text = null, newAnswers = null, yearID = null, subSectionKPIs = null, moduleObject }) => {
    var enterInSomeToleranceValidation = false;
    if (subSectionKPI.toleranceType && moduleObject.version && ![2, 4, 7].some(item => moduleObject.version.status === item)) {
        var toleranceKPILinkedRefNumberObject = null;

        if (subSectionKPI.toleranceType > 1 && subSectionKPI.toleranceType != 7) {
            toleranceKPILinkedRefNumberObject = getAnswers(
                {
                    'newAnswers': newAnswers,
                    'ref': subSectionKPI.toleranceKPILinkedRefNumber?.replace('[', '').replace(']', ''),
                    'row': row,
                    'column': column
                }
            )[0];
        }

        var querySelector = `#subSectionKPI_${subSectionKPI.subSectionKPIID}_${subSectionKPI.columnRowID}`;

        if (column != null) {
            querySelector += `_c${column}`;
        }
        if (row != null) {
            querySelector += `_r${row}`;
        }
        if (yearID != null) {
            querySelector += `_y${yearID}`;
        }

        var alert = document.querySelector(`${querySelector}_alert`);
        var input = document.querySelector(querySelector);

        if (subSectionKPI.toleranceType == 1) { //Range Type
            if (value != null && value != "") {
                value = parseFloat(value);

                if ((subSectionKPI.lowerTolerance != null && value < subSectionKPI.lowerTolerance.toFixed(2))
                    || (subSectionKPI.upperTolerance != null && value > subSectionKPI.upperTolerance.toFixed(2))) {
                    enterInSomeToleranceValidation = true
                }
            }
        } else if (subSectionKPI.toleranceType == 2) { //Greater Than
            if (value != null && value != "") {
                value = parseFloat(value);

                if (toleranceKPILinkedRefNumberObject && (value <= toleranceKPILinkedRefNumberObject.numberValue)) {
                    enterInSomeToleranceValidation = true;
                }
            }
        } else if (subSectionKPI.toleranceType == 3) { //Lower Than
            if (value != null && value != "") {
                value = parseFloat(value);

                if (toleranceKPILinkedRefNumberObject && (value >= toleranceKPILinkedRefNumberObject.numberValue)) {
                    enterInSomeToleranceValidation = true;
                }
            }
        } else if (subSectionKPI.toleranceType == 4) { //Equal To
            if (value != null && value != "") {
                value = parseFloat(value);

                if (toleranceKPILinkedRefNumberObject && (value != toleranceKPILinkedRefNumberObject.numberValue)) {
                    enterInSomeToleranceValidation = true;
                }
            }
        } else if (subSectionKPI.toleranceType == 5) { //Greater Than or Equal To
            if (value != null && value != "") {
                value = parseFloat(value);

                if (toleranceKPILinkedRefNumberObject && (value < toleranceKPILinkedRefNumberObject.numberValue)) {
                    enterInSomeToleranceValidation = true;
                }
            }
        } else if (subSectionKPI.toleranceType == 6) { //Lower Than or Equal To
            if (value != null && value != "") {
                value = parseFloat(value);

                if (toleranceKPILinkedRefNumberObject && (value > toleranceKPILinkedRefNumberObject.numberValue)) {
                    enterInSomeToleranceValidation = true;
                }
            }
        } else if (subSectionKPI.toleranceType == 7) { // Custom
            if (subSectionKPI.toleranceKPILinkedRefNumber != null) {
                var validateDate = false;
                if (moduleObject.version && ![0, 1, 3, 5, 6, 8].some(item => moduleObject.version.status === item)) {
                    validateDate = true
                }
                enterInSomeToleranceValidation = !calculation(subSectionKPI.refNumber, subSectionKPI.toleranceKPILinkedRefNumber, newAnswers, moduleObject.version, column, row, moduleObject, validateDate);
            }
        }
        else if (subSectionKPI.toleranceType == 8) { //Validate time
            if (text != null && text != "") {
                const [hours, minutes, seconds] = text.split(':').map(Number);

                if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
                    enterInSomeToleranceValidation = true;
                }
                else if ((!isNaN(hours) || !isNaN(minutes) || !isNaN(seconds)) && (minutes > 59 || seconds > 59)) {
                    enterInSomeToleranceValidation = true;
                }
            }
        }
        console.log(enterInSomeToleranceValidation)
        if (enterInSomeToleranceValidation) {
            setTimeout(() => {
                input != null && input.classList.add("alert-input");
            }, 500)
            alert != null && alert.classList.remove("hide");
        } else {
            setTimeout(() => {
                input != null && input.classList.remove("alert-input");
                alert != null && alert.classList.add("hide");
            }, 500)
        }

    }

    // CheckDependencyKPI(subSectionKPI, newAnswers, subSectionKPIs);
}

const CheckDependencyKPI = (sskpi, newAnswers, subSectionKPIs) => {
    if (subSectionKPIs != null) {
        var linkedKpis = subSectionKPIs.filter(ssk => ssk.toleranceKPILinkedRefNumber == sskpi.refNumber);

        linkedKpis.map((ssk) => {
            var answer = newAnswers.find(x => x.refNumber == ssk.refNumber);

            if (answer) {
                toleranceValidations(
                    {
                        'subSectionKPI': ssk,
                        'column': answer.column,
                        'row': answer.row,
                        'value': answer.numberValue,
                        'text': answer.textValue,
                        'newAnswers': newAnswers,
                        'yearID': answer.yearID,
                        'subSectionKPIs': subSectionKPIs
                    });

            }
        })
    }
}