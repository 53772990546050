import styled from 'styled-components';

export const CommentTextArea = styled.textarea`
    background: #F6F8FA 0% 0% no-repeat padding-box;
    border: 1px solid #D3D2E4;
    border-radius: 4px;
    resize: vertical;
    width: 95%;
    margin: 0.5rem 1rem;
    position: relative;

    &.block-comment{
        background-color: transparent !important;
        border: 0 !important;
        resize: none !important;
        font: italic normal normal 14px Montserrat;
        text-align: left;
    }
`;

export const Table = styled.table`
    font-size: 13px;
    width: 100%;
    background-color: #FFFFFF;

    thead{
        background-color: rgb(252, 252, 252);
        border: 1px solid #ECECF3;
        z-index: 10;

        th.fixed-head {
            position: sticky;
            left: 0;
            z-index: 11;
        }
    }

    .primary-header{
        background-color: rgb(252, 252, 252);
        border: 1px solid #ECECF3;
        z-index: 10;

        th{
            font-weight: bold;
            padding: 7px;
            border-radius: 0!important;
            background-color: #E9EBEE;
        }
    }


    .country-table-header{
        background-color: #F6F8FA;
        border: 1px solid #ECECF3;

        span{
            color: black;
            font-weight: bold;
        }

        &:first-child{
            border-right: 0;
        }

        &:last-child{
            border-left: 0;
        }
    }

    tbody{
        background-color: #FFFFFFFF;

        tr.add-another{
            background-color: #F6F8FA;

            td{
                text-align: left!important;

                span{
                    font-weight: bold;
                    color: #387722;
                    opacity: 0.8;
                    cursor: pointer;

                    &:hover{
                        opacity: 1;
                    }
                }
            }
        }

        tr{
            border: 1px solid #ECECF3;
            border-left: #FFFFFF;
            border-right: #FFFFFF;

            .upload-btn-wrapper {
                position: relative;
                overflow: hidden;
                display: inline-block;
            }

            .btn-custom {
                background-color: transparent;
                border: 1px solid #B0B0BB;
                color: #B0B0BB;
                padding: 14px 40px 14px 40px;
                border-radius: 5px;
            }

            .upload-btn-wrapper input[type=file] {
                font-size: 100px;
                position: absolute;
                left: 0;
                top: 0;
                opacity: 0;
            }

            td{
                padding: 14px 1.5rem;

                /* &:first-child{
                    text-align: center;
                } */

                &:last-child{
                    /* text-align: center; */

                    .btn{
                        background-color: transparent;
                        border: 0;
                        padding: 0;
                        margin: 0;
                        cursor: unset;

                        &:focus{
                            background-color: transparent;
                            border: 0;
                            padding: 0;
                            margin: 0;
                            box-shadow: 0 0 0 0 transparent;
                        }

                        &:active{
                            background-color: transparent;
                            border: 0;
                            padding: 0;
                            margin: 0;
                            box-shadow: 0 0 0 0 transparent;
                        }
                    }

                    .dropdown-menu{
                        box-shadow: 0px 3px 20px #00000026;
                        border: 1px solid #ECECF3;
                        border-radius: 4px;
                        font-size: 13px;

                        .dropdown-item{
                            padding-left: 19px;
                            padding-right: 19px;

                            &:active{
                                background-color: #112A61;
                            }
                        }
                    }
                }

                input {
                    background: transparent;
                    color: #495057;
                    background-color: #FFFFFF;
                    flex: 1;
                    padding: 0.375rem 0.75rem;
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 1.5;
                    border: 1px solid #ced4da;
                    border-radius: 0.25rem;
                    width: 100%;
                    padding-left: 5px!important;
                    padding-right: 5px!important;

                    &::placeholder {
                    color: #e2e2e2;
                    }

                    &:focus{
                        box-shadow: none;
                    }

                    &:active{
                        box-shadow: none;
                    }

                    &:focus-visible{
                        box-shadow: none;
                        outline: none;
                    }

                    &:disabled{
                    background-color: #e9ecef;
                    }

                    option{
                    background-color: #FFFFFF;
                    }
                }

                select{
                    padding-left: 0.5rem;
                }

                select.read-only, input.read-only{
                    background-color: transparent;
                    border: 0;
                    width: 100%;
                }

                input.read-only-total{
                    background-color: #DEF2D6;
                    color: black;
                    font-weight: bold;
                    border: 1px solid #DEF2D6;
                }

                select.read-only, input.read-only, textarea.read-only{
                    background-color: transparent;
                    border: 0;
                    width: 100%;

                }

                input.read-only-total, textarea.read-only{
                    background-color: #DEF2D6;
                    color: black;
                    font-weight: bold;
                    border: 1px solid #DEF2D6;
                }

                input.no-total{
                    background-color: transparent;
                    border: 0;
                }

                .required-field{
                    border-color: #ff0000;
                }

                .required-comment svg{
                    color: #ff0000 !important;
                }

                .required-comment-textarea {
                    border-color: #ff0000;
                }

                .required-file svg{
                    color: #ff0000 !important;
                }

                .required-file-btn {
                    border-color: #ff0000 !important;
                    color: #ff0000!important;
                }

                .required-kpi{
                    color: #ff0000;
                }
            }

            .no-records{
                text-align: center;
            }

            &:last-child{
                td:first-child{
                    border-radius: 0px 0px 0px 8px;
                }

                td:last-child{
                    border-radius: 0px 0px 8px 0px;
                }

                border-bottom: #F6F8FA;
            }
        }
    }
`;

export const Container = styled.div`
    &.scroll-div{
        overflow-x: auto;
        overflow-y: hidden;
    }

    .div-header{
        background-color: #112A61;
        border-radius: 8px 8px 0px 0px;
        padding: 14px;
        font-weight: bold;
        margin-top: 0.5rem;
        font-size: 13px;
    }

    .sub-section-description{
            font-weight: bold;
            color: #FFFFFFFF;
            padding: 0.5rem;
            height: 2rem;
            border-radius: 8px 8px 0px 0px;
    }

    .sub-section-guidance{
            color: #FFFFFFFF;
            padding: 0 0.5rem 0.5rem 0.5rem;
            font-size: 12px;
            font-weight: 400;
            margin-top: 0.5rem;
    }

    .tableFixHead {
        overflow-y: auto; /* make the table scrollable if height is more than 200 px  */
        overflow-x:auto;
    }

    .fixed-col{
            background-color: #FFFFFFFF;
            position: sticky;
            left: 0;
            z-index: 9;
    }
`;
