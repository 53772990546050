import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import Api, { exceptionNotificationAPI } from "../../services/api";
import Button from "../Button";
import { useHistory } from 'react-router-dom';
import { error_message, info_message, success_message } from "../Toast";
// import ModalBody from 'react-bootstrap/ModalBody';
import { Header, ContentTitle, HeaderContainer, ModalBody, DownloadButton, Card } from './styles';
import TableLoading from "../TableLoading";
import { MdChat, MdFileDownload } from "react-icons/md";
import { AiOutlineExport } from "react-icons/ai";
import { IoMdAddCircle } from "react-icons/io";
import { useAuth } from "../../hooks/auth";

export default function ModalSelectWaiver({ showModalSelectWaiver, setShowModalSelectWaiver, module, reportYearID, companyID, selectedStatus = null, selectedModule }) {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [userObject, setUserObject] = useState(JSON.parse(localStorage.getItem('@QualityApp:user')));
    const { userRoles } = useAuth();
    const history = useHistory();

    useEffect(() => {
        setLoading(true)
        if (showModalSelectWaiver == true) {
            Api.get(`FormValue/GetAllWaiverVersionsByReportYearAndCompany?reportYearID=${reportYearID}&companyID=${companyID}${selectedStatus != null ? `&selectedStatus=${selectedStatus}` : ""}`).then((result) => {
                if (result && result.data) {
                    setData(result.data.response);
                    setLoading(false)
                }
            }).catch((error) => {
                exceptionNotificationAPI(error);
            });
        }
    }, [showModalSelectWaiver]);

    const onClickModuleRedirect = (operationName, module, companyID, operationID) => {
        var url;

        userObject.selectedCompanyID = companyID;
        localStorage.setItem('@QualityApp:user', JSON.stringify(userObject));


        if (module.isWaiverModule) {
            url = `/report/${operationName.replaceAll(' ', '').replaceAll('&', '').toLowerCase()}/${module.name.replaceAll(' ', '').replaceAll('&', '').toLowerCase()}/${module.waiverIdentifier}`;
        }

        history.push(url, { operationID });
        window.location.reload(true)
    }

    const exportAllWaivers = () => {


        var data = {
            ReportYears: [userObject.selectedReportYear],
            Companies: [companyID],
            Modules: null,
            Type: 7000
        };


        
        Api.post(`export/get`, data, { responseType: 'blob' })
            .then((response) => {
                if (typeof window.navigator.msSaveBlob !== 'undefined') {
                    // Internet Explorer
                    window.navigator.msSaveBlob(response.data, "Waiver_Registering.xlsx");
                } else {
                    // Outros navegadores
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Waiver_Registering.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                }
            })
            .catch((error) => {
                // Lidar com erros
                exceptionNotificationAPI(error);
            });
    }

    const onClickButtonCompareVersion = (companyID, reportYearID, operationID, moduleID) => {
        history.push(`/compareVersion/companyID=${companyID}&reportYearID=${reportYearID}&operationID=${operationID}&moduleID=${moduleID}`);
    };

    const onClickButtonDiscussionDashboard = (companyID, reportYearID, operationID, moduleID) => {
        history.push(`/discussionDashboard/companyID=${companyID}&reportYearID=${reportYearID}&operationID=${operationID}&moduleID=${moduleID}`);
    };

    const createNewWaiver = () => {
        var url;
        var newArray = data.map(el => {
            return el.waiverIdentifier;
        })
        
        newArray = newArray.sort().reverse();
        var newWaiverId = newArray != null && newArray.length > 0 ? newArray[0] + 1 : 1;

        userObject.selectedCompanyID = companyID;
        localStorage.setItem('@QualityApp:user', JSON.stringify(userObject));
        //var moduleW = data.find(x => x.isWaiverModule == true);

        url = `/report/${selectedModule.operationName.replaceAll(' ', '').replaceAll('&', '').toLowerCase()}/${selectedModule.description.replaceAll(' ', '').replaceAll('&', '').toLowerCase()}/${newWaiverId}`;


        history.push(url);
        window.location.reload(true)
    }


    return (<HeaderContainer>
        <Modal
            show={showModalSelectWaiver}
            onHide={() => {
                setShowModalSelectWaiver(false);
            }}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <ModalHeader className="row w-100 align-items-center closebtn" style={{ border: 0 }} closeButton>
                <div className="col-lg-6">
                    <ModalTitle id="contained-modal-title-vcenter">
                        <Header>Select a Waiver version</Header>
                    </ModalTitle>
                </div>
                <div className="col-lg-2">
                        {data && data.length > 0 ?
                            <>
                                {
                                    !loading &&
                                        <DownloadButton className="btn_download d-flex align-items-center" onClick={() => exportAllWaivers()}>
                                                <AiOutlineExport size={20} color="#FFF"/>
                                                <h6 className="evidence_download">Export</h6>
                                        </DownloadButton>                                        
                                }
                            </>
                            :
                            loading ?
                                <TableLoading />
                                :
                                <div></div>
                        }
                    </div>
                {
                    userRoles.includes("RoleInfoHolder") &&
                    <div className="col-lg-3">
                        {!loading &&
                            <>
                               <DownloadButton className="btn_download d-flex align-items-center" onClick={() => createNewWaiver()}>
                                    <IoMdAddCircle size={20} color="#FFF"/>
                                    <h6 className="evidence_download" >Create a new waiver</h6>
                                </DownloadButton>                                        
                                
                            </>                           
                        }
                    </div>
                }

            </ModalHeader>
            <ModalBody>
                <div className="container dashboard-body">
                    {data && data.length > 0 ?
                        <>
                            {
                                loading ?
                                    <TableLoading />
                                    :
                                    <>
                                        <Card className="show">
                                            <div className={`card`}>
                                                {
                                                    data.map((item) => {
                                                        var today = new moment()
                                                        return <>
                                                            <div className="card-row">
                                                                <div className="card-body" item={item.moduleID} onClick={() => { onClickModuleRedirect(module.operationName, item, companyID) }}>
                                                                    <div className="col-md-3">
                                                                        {item.description}
                                                                    </div>
                                                                    <div className="col-md-2 last-change-column">
                                                                        {item.dateLastChange != null ? item.dateLastChange : ""}
                                                                    </div>
                                                                    <div className="col-md-2 year-version-column">
                                                                        {item.sentBy}
                                                                    </div>
                                                                    {
                                                                        item.deadline != null ?
                                                                            <div className={`col-md-2 status ${item.classReject}`}>
                                                                                {`Deadline: ${moment(item.deadline, "DD/MM/YYYY").format("DD/MM/YYYY")}`}
                                                                            </div>
                                                                            : <div className="col-md-2"></div>
                                                                    }
                                                                    <div className="col-md-2">
                                                                        <div className={item.statusNumber == 0 && item.status != null ?
                                                                            "status notStarted"
                                                                            :
                                                                            ((item.statusNumber == 3 || item.statusNumber == 5 || item.statusNumber == 8) ?
                                                                                "status rejected"
                                                                                :
                                                                                "status complete")}>
                                                                            {item.status}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-1">
                                                                        {
                                                                            <div className={item.percentage == 0 ?
                                                                                "status pending"
                                                                                :
                                                                                "status complete"} style={{ width: '80%' }}>
                                                                                {`${item.percentage}%`}
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="card-row-buttons-body">
                                                                    <button title="Compare Versions" className="card-row-button version-button" onClick={() => onClickButtonCompareVersion(companyID, reportYearID, module.operationID, item.moduleID)}>
                                                                        v{item.version}
                                                                    </button>
                                                                    <button title="Discussion Dashboard" className="card-row-button-last" onClick={() => onClickButtonDiscussionDashboard(companyID, reportYearID, module.operationID, module.moduleID)}>
                                                                        <MdChat size={20} />
                                                                    </button>
                                                                </div>
                                                            </div>

                                                        </>
                                                    })
                                                }
                                            </div>
                                        </Card>
                                    </>
                            }
                        </>
                        :
                        loading ?
                            <TableLoading />
                            :
                            <div>There is no data to list here</div>
                    }
                </div>
            </ModalBody>

        </Modal>
    </HeaderContainer>
    );
}