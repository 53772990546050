import TableCrud from '../../components/TableCrud';
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../hooks/auth';
import { useParams } from 'react-router-dom';
import TableLoading from '../../components/TableLoading/index';
import { error_message } from '../../components/Toast/index.jsx';
import Api, { exceptionNotificationAPI } from '../../services/api';
import { Container, Table} from './styles';

const MigrateDashboard = () => {

    const { user, userRoles } = useAuth();
    const { companyID, reportYearID, moduleID } = useParams();

    const [userObject, setUserObject] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingView, setLoadingView] = useState(false);

    const [blockToYear, setBlockToYear] = useState(false);

    //Operation Select Variables
    const [selectedOperation, setSelectedOperation] = useState();
    const [operations, setOperations] = useState('');

    //Report Year Select Variables
    const [reportYearList, setReportYearList] = useState([]);
    const [fromReportYear, setFromReportYear] = useState('');
    const [toReportYear, setToReportYear] = useState('');

    useEffect(() => {
        var userAux = typeof (user) == "string" ? JSON.parse(user) : user;
        setUserObject(userAux);
        loadCropYears(userAux);
        loadOperations(userAux.selectedReportYearID);
        setLoading(false);
    }, []);

    const loadCropYears = () => {
        Api.get(`FormValue/getAllCropsYears`).then((result) => {
            setLoading(true);
            if (result && result.data) {
                setReportYearList(result.data.response);
            }
            setLoading(false);
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const loadOperations = (reportYearID) => {
        Api.get(`FormValue/getAllOperationsByReportYear?reportYearID=${reportYearID}`).then((result) => {
            if (result && result.data) {
                let response = result.data.response;
                if(response.length > 0 ){
                    setOperations(response);
                } else {
                    response.push({
                        operationID: "",
                        description: `No items found, please select another Report Year`,
                    })
                    setOperations(response);
                }

                setLoading(false);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const onChangeOperation = (value) => {
        setSelectedOperation(value);
        var op = operations.find(x => x.operationID == value);
        if(op.description.toLowerCase().includes('glidepath')){
            setBlockToYear(true);
        } else {
            setBlockToYear(false);
        }
    }

    const onChangeFromReportYearID = (value) => {
        setFromReportYear(value);
        var op = operations.find(x => x.operationID == selectedOperation);
        if(op.description.toLowerCase().includes('glidepath')){
            var year = reportYearList.find(x => x.reportYearID == value);
            var nextYear = (parseInt(year.year)+1);
            var nextReportYear = reportYearList.find(x => x.year == nextYear);
            setToReportYear(nextReportYear?.reportYearID ? nextReportYear?.reportYearID : 0);
        }
    }

    const onChangeToReportYearID = (value) => {
        setToReportYear(value);
    }

    const migrateStructure = () => {
        setLoadingView(true);

        if (selectedOperation && fromReportYear && toReportYear) {
            Api.get(`migrate/MigrateStructure?selectedOperation=${selectedOperation}&fromReportYear=${fromReportYear}&toReportYear=${toReportYear}`).then((result) => {
                if (result && result.data) {
                    setLoadingView(false);
                }
                setLoadingView(false);
            }).catch((error) => {
                setLoadingView(false);
                exceptionNotificationAPI(error);
            });
        } else {
            error_message("Error ocurred during migration");
            setLoadingView(false);
        }
    }

    return (
        <Container className="container">
            <div className="row mt-2">
                <select className="col-md-12 mt-2" onChange={(x) => onChangeOperation(x.target.value)} value={selectedOperation ? selectedOperation : ""}>
                        <option key={0} value={0}>Select form to migrate</option>
                        {
                            operations ?
                            operations.map(operation => {
                                return <option key={operation.operationID} value={operation.operationID}>{operation.description}</option>
                            })
                            :
                            <></>
                        }
                </select>
                <select className="col-md-12 mt-2" onChange={(x) => onChangeFromReportYearID(x.target.value)} value={fromReportYear ? fromReportYear : ""}>
                            <option key={0} value={0}>Select Report Year to copy from</option>
                            {
                                 reportYearList ?
                                 reportYearList.map(reportYear => {
                                         return <option key={reportYear.reportYearID} value={reportYear.reportYearID}>{reportYear.year}</option>
                                     })
                                 :
                                     <></>
                            }
                </select>
                <select disabled={blockToYear} className="col-md-12 mt-2" onChange={(x) => onChangeToReportYearID(x.target.value)} value={toReportYear ? toReportYear : ""}>
                            <option key={0} value={0}>Select Report Year to copy to</option>
                            {
                                 reportYearList ?
                                 reportYearList.map(reportYear => {
                                         return <option key={reportYear.reportYearID} value={reportYear.reportYearID}>{reportYear.year}</option>
                                     })
                                 :
                                     <></>
                            }
                </select>
                {
                    loadingView ?
                    <div className="mt-2" style={{ padding: 0 }}>
                        <TableLoading />
                    </div> : <button className="col-md-12 apply-btn mt-2" onClick={() => migrateStructure()}>Migrate</button>
                }

            </div>
        </Container>
    );
}

export default MigrateDashboard;
