import React, { useState, useEffect } from 'react';
import { Container, Table, CollapseStyled } from './styles';
import { useAuth } from '../../hooks/auth';
import { useParams } from 'react-router-dom';
import Api, { exceptionNotificationAPI } from '../../services/api';
import TableLoading from '../../components/TableLoading/index';
import { error_message } from '../../components/Toast/index.jsx';
import { FaArrowRight, FaAngleDown, FaAngleUp, FaArrowUp, FaArrowDown } from 'react-icons/fa';
import GetTypeInput from '../../components/FormInput/set';
import NumberFormat from 'react-number-format';
import ModalLoading from '../../components/ModalLoading/index';

const VersionComparison = () => {
    const { user } = useAuth();
    const { companyID, reportYearID, operationID, moduleID } = useParams();
    const [userObject, setUserObject] = useState(null);
    const [loading, setLoading] = useState(true);

    //Company Select Variables
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(companyID);

    //Operation Select Variables
    const [operations, setOperations] = useState([]);
    const [selectedOperation, setSelectedOperation] = useState(operationID);

    //Module Select Variables
    const [modules, setModules] = useState([]);
    const [modulesFiltered, setModulesFiltered] = useState([]);
    const [selectedModule, setSelectedModule] = useState(moduleID);

    //Crop Year Select Variables
    const [cropYears, setCropYears] = useState([]);
    const [selectedReportYear, setSelectedReportYear] = useState(reportYearID);
    const [selectedReportYearOne, setSelectedReportYearOne] = useState(reportYearID);
    const [selectedReportYearTwo, setSelectedReportYearTwo] = useState(reportYearID);

    //Version Select Variables
    const [versionsSelectOne, setVersionsSelectOne] = useState([]);
    const [versionsSelectTwo, setVersionsSelectTwo] = useState([]);

    //Checkbox Data Changed Variables
    const [showJustDataChanged, setShowJustDataChanged] = useState(true);

    //Checkbox just inputs
    const [showJustInput, setShowJustInput] = useState(false);

    //Ref Number Select Variables
    const [refNumberSelect, setRefNumberSelect] = useState([]);
    const [selectedRefNumber, setSelectedRefNumber] = useState(null);

    //Tobacco Types Select Variables
    const [tobaccoTypes, setTobaccoTypes] = useState([]);
    const [selectedTobaccoType, setSelectedTobaccoType] = useState(null);

    //Load Table Versions
    const [selectedVersionOne, setSelectedVersionOne] = useState(null);
    const [selectedVersionTwo, setSelectedVersionTwo] = useState(null);
    const [selectedVersionNumberOne, setSelectedVersionNumberOne] = useState(null);
    const [selectedVersionNumberTwo, setSelectedVersionNumberTwo] = useState(null);
    const [loadingView, setLoadingView] = useState(false);
    const [viewData, setViewData] = useState(null);
    const [viewSpecificData, setViewSpecificData] = useState(null);
    const [loadingFile, setLoadingFile] = useState(false);
    const [isFirstLoading, setIsFirstLoading] = useState(false);
    const [selectedCompanyIsSourcingGroup, setSelectedCompanyIsSourcingGroup] = useState(false);
    const [compareYear, setCompareYear] = useState(false);
    var yearTarget = null;

    useEffect(() => {
        var userAux = typeof (user) == "string" ? JSON.parse(user) : user;
        setUserObject(userAux);

        if (!userAux.companyID || userAux.groupID) {
            loadCompanies(userAux.groupID);
        } else {
            setSelectedCompany(userAux.companyID);
        }

        loadCropYears(userAux);
    }, []);

    useEffect(() => {

        if (!compareYear) {
            if (selectedModule && selectedCompany && selectedReportYear) {
                if (modulesFiltered.find(x => x.moduleID == selectedModule)?.moduleExternalID == 15000) {
                    if (selectedTobaccoType) {
                        loadVersions();
                    }
                }
                else {
                    loadVersions();
                }
            }
            setSelectedVersionOne(null);
            setVersionsSelectTwo([]);
        } else {
            if (selectedModule && selectedCompany && selectedReportYearOne) {
                if (modulesFiltered.find(x => x.moduleID == selectedModule)?.moduleExternalID == 15000) {
                    if (selectedTobaccoType) {
                        loadVersionsCompareYear(true);
                    }
                }
                else {
                    loadVersionsCompareYear(true);
                }
            }
        }


    }, [selectedModule, selectedCompany, selectedOperation, selectedReportYear, selectedTobaccoType, selectedReportYearOne, compareYear]);


    useEffect(() => {
        if (!compareYear) {
            if (selectedOperation) {
                loadModules(selectedCompanyIsSourcingGroup, selectedReportYear);
            }
        } else {
            if (selectedOperation) {
                loadModules(selectedCompanyIsSourcingGroup, selectedReportYearOne);
            }
        }
    }, [selectedOperation]);

    useEffect(() => {
        if (!compareYear) {
            if (selectedReportYear) {
                onChangeCropYear(selectedReportYear)
            }
        } else {
            if (selectedReportYearOne) {
                onChangeCropYearCompareYear(selectedReportYearOne)
            }
        }
    }, [compareYear]);


   

    const loadCompanies = (groupID = null) => {
        if (groupID != null) {
            Api.get(`Dashboard/getAllCompaniesFilteredByGroup`).then((result) => {
                if (result && result.data) {
                    setCompanies(result.data.response);
                    // setSelectedCompanyIndex([0]);
                }
            }).catch((error) => {
                exceptionNotificationAPI(error);
            });
        }
        else {
            Api.get(`company/getAll`).then((result) => {
                if (result && result.data) {
                    setCompanies(result.data.response);

                    if (companyID)
                        setSelectedCompany(companyID);
                    else
                        setSelectedCompany(result.data.response[0].companyID);
                }
            }).catch((error) => {
                exceptionNotificationAPI(error);
            });
        }
    }

    const loadCropYears = (userAux) => {
        Api.get(`FormValue/getAllCropsYears`).then((result) => {
            if (result && result.data) {
                setCropYears(result.data.response);

                if (reportYearID) {
                    setSelectedReportYear(reportYearID);
                    setSelectedReportYearOne(reportYearID);
                    setSelectedReportYearTwo(reportYearID);
                    loadModules(selectedCompanyIsSourcingGroup, reportYearID)
                    loadOperations(reportYearID);
                }
                else {
                    if (userAux != null) {
                        setSelectedReportYear(userAux.selectedReportYearID);
                        setSelectedReportYearOne(userAux.selectedReportYearID);
                        setSelectedReportYearTwo(userAux.selectedReportYearID);
                        loadModules(selectedCompanyIsSourcingGroup, userAux.selectedReportYearID)
                        loadOperations(userAux.selectedReportYearID);
                    }


                }
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const loadOperations = (reportYearID) => {
        Api.get(`FormValue/getAllOperationsByReportYear?reportYearID=${reportYearID}`).then((result) => {
            if (result && result.data) {

                let response = result.data.response;
                // console.log('EU SOU A RESPOSTA', response);
                if (response.length > 0) {
                    setOperations(response);
                    setSelectedOperation(response[0].operationID);
                } else {
                    response.push({
                        operationID: "",
                        description: `No items found, please select another Report Year`,
                    })
                    setOperations(response);
                }

                //if (operationID)
                //    setSelectedOperation(operationID);
                //else
                //    setSelectedOperation(result.data.response[0].operationID);

                setLoading(false);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const loadModules = (IsSourcingGroup, reportYearID) => {
        Api.get(`Dashboard/GetAllByReportYear?reportYearID=${reportYearID}`).then((result) => {
            if (result && result.data) {
                setModules(result.data.response);
                setModulesFiltered(result.data.response.filter(x => x.operationID == selectedOperation));
                if (selectedOperation) {
                    // console.log(IsSourcingGroup)
                    if (!IsSourcingGroup) {
                        setModulesFiltered(result.data.response.filter(x => x.operationID == selectedOperation && x.description != "GLT"));
                    }
                    else {
                        setModulesFiltered(result.data.response.filter(x => x.operationID == selectedOperation));
                    }
                }
                else {
                    if (!IsSourcingGroup) {
                        setModulesFiltered(result.data.response.filter(x => x.description != "GLT"));
                    }
                    else {
                        setModulesFiltered(result.data.response);
                    }
                }
                //if (moduleID)
                //    setSelectedModule(moduleID);
                //else
                //    setSelectedModule(result.data.response[0].moduleID);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const onChangeCompany = (value) => {
        var operationID = operations && operations.length > 0 ? operations[0].operationID : "";

        setSelectedCompany(value);
        setSelectedOperation(operationID);

        var modulesAux = value != "" ? modules.filter(x => x.operationID == operationID) : modules;

        setModulesFiltered(modulesAux);
        //setSelectedModule(modulesAux[0].moduleID);
    }

    const onChangeCropYear = (value) => {
        setSelectedReportYear(value);
        loadOperations(value);
        loadModules(selectedCompanyIsSourcingGroup, value);
        var operationID = operations && operations.length > 0 ? operations[0].operationID : "";
        setSelectedOperation(operationID);

        var modulesAux = value != "" ? modules.filter(x => x.operationID == operationID) : modules;

        setModulesFiltered(modulesAux);
        //setSelectedModule(modulesAux[0].moduleID);
    }

    const onChangeCropYearCompareYear = (value) => {
        setSelectedReportYearOne(value);
        loadOperations(value);
        loadModules(selectedCompanyIsSourcingGroup, value);
        var operationID = operations && operations.length > 0 ? operations[0].operationID : "";
        setSelectedOperation(operationID);

        var modulesAux = value != "" ? modules.filter(x => x.operationID == operationID) : modules;

        setModulesFiltered(modulesAux);
        //setSelectedModule(modulesAux[0].moduleID);
    }

    const onChangeOperation = (value) => {
        var modulesAux = value != "" ? modules.filter(x => x.operationID == value) : modules;

        var modID = modulesAux[0].moduleID;

        setSelectedOperation(value);
        setModulesFiltered(modulesAux);
        //setSelectedModule(modID);
    }

    const loadVersions = () => {
        Api.get(`FormValue/getAllVersionsByModuleAndCompanyAndReportYear?selectedCompanyID=${selectedCompany}&moduleID=${selectedModule}&reportYearID=${selectedReportYear}${selectedTobaccoType != null ? '&tobaccoTypeID=' + selectedTobaccoType : ""}`).then((result) => {
            setVersionsSelectOne(result.data.response);

            if (companyID && reportYearID && operationID && moduleID && moduleID == selectedModule) {
                if (result.data.response.length > 1) {
                    var newestVersionIndex = (result.data.response.length - 1);
                    var versionOne = result.data.response[newestVersionIndex - 1] ? result.data.response[newestVersionIndex - 1].formValueVersionID : "";
                    var versionTwo = result.data.response[newestVersionIndex] ? result.data.response[newestVersionIndex].formValueVersionID : "";

                    setSelectedVersionOne(versionOne);
                    setSelectedVersionTwo(versionTwo);

                    setVersionsSelectTwo(result.data.response.filter(x => x.formValueVersionID != versionOne));
                }
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const loadVersionsCompareYear = (isOne = true, twoID = null) => {
        if (isOne) {
            Api.get(`FormValue/getAllVersionsByModuleAndCompanyAndReportYear?selectedCompanyID=${selectedCompany}&moduleID=${selectedModule}&reportYearID=${selectedReportYearOne}${selectedTobaccoType != null ? '&tobaccoTypeID=' + selectedTobaccoType : ""}`).then((result) => {
                setVersionsSelectOne(result.data.response);
                if (companyID && reportYearID && operationID && moduleID && moduleID == selectedModule) {
                    if (result.data.response.length > 1) {
                        var newestVersionIndex = (result.data.response.length - 1);
                        var versionOne = result.data.response[newestVersionIndex - 1] ? result.data.response[newestVersionIndex - 1].formValueVersionID : "";
                        var versionTwo = result.data.response[newestVersionIndex] ? result.data.response[newestVersionIndex].formValueVersionID : "";

                        setSelectedVersionOne(versionOne);
                        setSelectedVersionTwo(versionTwo);

                        setVersionsSelectTwo(result.data.response.filter(x => x.formValueVersionID != versionOne));
                    }
                }
            }).catch((error) => {
                exceptionNotificationAPI(error);
            });
        } else {
            Api.get(`FormValue/getAllVersionsByModuleAndCompanyAndReportYear?selectedCompanyID=${selectedCompany}&moduleID=${selectedModule}&reportYearID=${twoID}${selectedTobaccoType != null ? '&tobaccoTypeID=' + selectedTobaccoType : ""}&compareYear=${compareYear}`).then((result) => {
                setVersionsSelectTwo(result.data.response);

                if (companyID && reportYearID && operationID && moduleID && moduleID == selectedModule) {
                    if (result.data.response.length > 1) {
                        var newestVersionIndex = (result.data.response.length - 1);
                        var versionOne = result.data.response[newestVersionIndex - 1] ? result.data.response[newestVersionIndex - 1].formValueVersionID : "";

                        setSelectedVersionTwo(versionOne);
                    }
                }
            }).catch((error) => {
                exceptionNotificationAPI(error);
            });
        }
    }


    const onChangeReportYearTwo = (value) => {
        setSelectedReportYearTwo(value);
        loadVersionsCompareYear(false, value)
    }


    const onChangeVersionOne = (value) => {
        setSelectedVersionTwo(null);
        setSelectedVersionOne(value);
        if ((!compareYear) || (compareYear && selectedReportYearOne == selectedReportYearTwo)) {
            setVersionsSelectTwo(versionsSelectOne.filter(x => x.formValueVersionID != value));
        } else if(compareYear && selectedReportYearOne != selectedReportYearTwo){
            loadVersionsCompareYear(false, selectedReportYearTwo)
        }
    }

    const loadFormValueByFilter = () => {
        setLoadingView(true);

        if (selectedCompany && selectedModule && selectedVersionOne && selectedVersionTwo) {
            var versionNumberOne = versionsSelectOne.find(x => x.formValueVersionID == selectedVersionOne).version;
            var versionNumberTwo = versionsSelectTwo.find(x => x.formValueVersionID == selectedVersionTwo).version;

            setSelectedVersionNumberOne(`Version ${compareYear ? versionNumberOne + " (" + cropYears.find(e => e.reportYearID == selectedReportYearOne)?.year + ")" : versionNumberOne}`);
            setSelectedVersionNumberTwo(`Version ${compareYear ? versionNumberTwo + " (" + cropYears.find(e => e.reportYearID == selectedReportYearTwo)?.year + ")" : versionNumberTwo}`);

            Api.get(`FormValue/GetFormValueByModuleAndVersions?selectedCompanyID=${selectedCompany}&moduleID=${selectedModule}&versionOneID=${selectedVersionOne}&versionTwoID=${selectedVersionTwo}&showJustInput=${showJustInput}&compareYear=${compareYear}`).then((result) => {
                if (result && result.data) {
                    var specificData = [];
                    var viewDataAux = result.data.response;

                    if (showJustDataChanged) {
                        viewDataAux.versionComparisonList = viewDataAux.versionComparisonList.filter(x => x.valueChanged
                            || x.commentChanged
                            || x.fileChanged);
                    }

                    //Ref Number filter (Select)
                    var refNumbers = viewDataAux.versionComparisonList.filter((thing, i, arr) => {
                        return arr.findIndex(t => t.kpiRefNumber === thing.kpiRefNumber) === i;
                    });

                    viewDataAux.versionComparisonList.map((item) => {
                        item.collapsed = (item.commentOne || item.commentTwo || item.fileOne || item.fileTwo) ? true : false;
                    });

                    if (selectedModule == 1) {
                        specificData = viewDataAux.versionComparisonList.filter(x => x.kpiRefNumber == "1205");
                        viewDataAux.versionComparisonList = viewDataAux.versionComparisonList.filter(x => x.kpiRefNumber != "1205");
                    }

                    setViewData(viewDataAux);
                    setViewSpecificData(specificData);
                    setRefNumberSelect(refNumbers);
                }

                setSelectedRefNumber(null);
                setLoadingView(false);
            }).catch((error) => {
                exceptionNotificationAPI(error);
            });
        } else {
            error_message("May you select all filters");
            setLoadingView(false);
        }
    }

    const onChangeRefNumberFilter = (value) => {
        var filter, table, tr, td, i, txtValue;
        filter = value;
        table = document.getElementById("versionTable");
        tr = table.getElementsByTagName("tr");
        for (i = 0; i < tr.length; i++) {
            td = tr[i].getElementsByTagName("td")[0];
            if (td) {
                txtValue = td.textContent || td.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    tr[i].style.display = "";
                } else {
                    tr[i].style.display = "none";
                }
            }
        }

        setSelectedRefNumber(value);
    }

    const getFile = async (fileName) => {
        setLoadingFile(true);

        await Api.get(`FormValue/getFile/${fileName}`, { responseType: 'blob' })
            .then(res => {
                let url = window.URL.createObjectURL(res.data);
                window.open(url);
                setLoadingFile(false);
            });
    }

    const collapseFunction = async (collapsed, index) => {
        var newVersionComparisonList = viewData.versionComparisonList;

        newVersionComparisonList[index].collapsed = collapsed;

        setViewData({ ...viewData, versionComparisonList: newVersionComparisonList });
    }

    useEffect(() => {
        if (selectedCompany != null && selectedReportYear != null && selectedOperation != null && selectedModule != null && selectedVersionOne && selectedVersionTwo) {
            if (!isFirstLoading && !compareYear) {
                loadFormValueByFilter();
                setIsFirstLoading(true);
            }
        }
    }, [selectedCompany, selectedReportYear, selectedOperation, selectedModule]);

    return (
        !loading && userObject ?
            <Container className="container">
                <div className="row mt-2">
                    {
                        userObject && (!userObject.companyID || userObject.groupID) &&
                        <select className="col-md-12 mt-2" onChange={(x) => onChangeCompany(x.target.value)} value={selectedCompany ? selectedCompany : ""}>
                            <option key={0} value={""}>Select a company</option>
                            {
                                companies ?
                                    companies.map(company => {
                                        return <option key={company.companyID} value={company.companyID}>{company.name}</option>
                                    })
                                    :
                                    <></>
                            }
                        </select>
                    }
                    {
                        !compareYear ?
                            <select className="col-md-12 mt-2" onChange={(x) => onChangeCropYear(x.target.value)} value={selectedReportYear ? selectedReportYear : ""}>
                                {
                                    cropYears ?
                                        cropYears.map(cropYear => {
                                            return <option key={cropYear.reportYearID} value={cropYear.reportYearID}>{cropYear.year}</option>
                                        })
                                        :
                                        <></>
                                }
                            </select>
                            : <></>
                    }
                    <select className="col-md-12 mt-2" onChange={(x) => onChangeOperation(x.target.value)} value={selectedOperation ? selectedOperation : ""}>
                        {
                            operations ?
                                operations.map(operation => {
                                    return <option key={operation.operationID} value={operation.operationID}>{operation.description}</option>
                                })
                                :
                                <></>
                        }
                    </select>
                    <select className="col-md-12 mt-2" onChange={(x) => {
                        var moduleID = x.target.value;
                        setSelectedModule(moduleID);
                        if (modulesFiltered.find(x => x.moduleID == moduleID)?.moduleExternalID != 15000) {
                            setSelectedTobaccoType(null);
                        };
                        return;
                    }
                    } value={selectedModule ? selectedModule : ""}>
                        <option value={""}>Select a module</option>
                        {
                            modulesFiltered ?
                                modulesFiltered.filter(module => module.isActive === true)
                                .map(module => {
                                    return <option key={module.moduleID} value={module.moduleID}>{module.description}</option>
                                })

                                :
                                <></>

                        }
                    </select>
                    {
                        selectedModule ? modulesFiltered.find(x => x.moduleID == selectedModule)?.moduleExternalID == 15000 &&
                            <select className="col-md-12 mt-2" onChange={(x) => setSelectedTobaccoType(x.target.value)} value={selectedTobaccoType ? selectedTobaccoType : ""}>
                                <option value="" >Tobacco Type</option>
                                {
                                    modulesFiltered ?
                                        tobaccoTypes.map(tobaccoType => {
                                            return <option key={tobaccoType.tobaccoTypeID} value={tobaccoType.tobaccoTypeID}>{tobaccoType.name}</option>
                                        })
                                        :
                                        <></>
                                }
                            </select>
                            :
                            <>
                            </>
                    }
                    {
                        compareYear ?
                            <>
                                <select className="col-md-6 mt-2" onChange={(x) => onChangeCropYearCompareYear(x.target.value)} value={selectedReportYearOne ? selectedReportYearOne : ""}>
                                    {
                                        cropYears ?
                                            cropYears.map(cropYear => {
                                                return <option key={cropYear.reportYearID} value={cropYear.reportYearID}>{cropYear.year}</option>
                                            })
                                            :
                                            <></>
                                    }
                                </select>
                                <select className="col-md-6 mt-2" onChange={(x) => onChangeReportYearTwo(x.target.value)} disabled={selectedVersionOne != null && selectedVersionOne != "" ? false : true} value={selectedReportYearTwo ? selectedReportYearTwo : ""}>
                                    {
                                        cropYears ?
                                            cropYears.map(cropYear => {
                                                return <option key={cropYear.reportYearID} value={cropYear.reportYearID}>{cropYear.year}</option>
                                            })
                                            :
                                            <></>
                                    }
                                </select>
                            </>
                            : <></>
                    }
                    <select className="col-md-6 mt-2" onChange={(x) => onChangeVersionOne(x.target.value)} value={selectedVersionOne ? selectedVersionOne : ""}>
                        <option value="">Select a Version</option>
                        {
                            versionsSelectOne.length > 0 ?
                                versionsSelectOne.map(version => {
                                    return <option key={version.formValueVersionID} value={version.formValueVersionID}>{`Version ${version.version} ${version.isWaiverVersion ? " - Waiver " + version.waiverIdentifier : ""}`}</option>
                                })
                                :
                                <></>
                        }
                    </select>
                    <select className="col-md-6 mt-2" onChange={(x) => setSelectedVersionTwo(x.target.value)} disabled={selectedVersionOne != null && selectedVersionOne != "" ? false : true} value={selectedVersionTwo ? selectedVersionTwo : ""}>
                        <option value="">Select a Version</option>
                        {
                            versionsSelectTwo.length > 0 ?
                                versionsSelectTwo.map(version => {
                                    return <option key={version.formValueVersionID} value={version.formValueVersionID}>{`Version ${version.version} ${version.isWaiverVersion ? " - Waiver " + version.waiverIdentifier : ""}`}</option>
                                })
                                :
                                <></>
                        }
                    </select>
                    <div className="form-check col-md-12 mt-2">
                        <input className="form-check-input" type="checkbox" id="showJustChanged" onChange={() => setShowJustDataChanged(!showJustDataChanged)} checked={showJustDataChanged ? true : false} />
                        <label className="form-check-label">
                            Show just data changed?
                        </label>
                    </div>
                    <div className="form-check col-md-12 mt-2">
                        <input className="form-check-input" type="checkbox" id="showJustInput" onChange={() => setShowJustInput(!showJustInput)} checked={showJustInput ? true : false} />
                        <label className="form-check-label">
                            Show just inputs?
                        </label>
                    </div>
                    <div className="form-check col-md-12 mt-2">
                        <input className="form-check-input" type="checkbox" id="compareYear" onChange={() => setCompareYear(!compareYear)} checked={compareYear ? true : false} />
                        <label className="form-check-label">
                            Compare years?
                        </label>
                    </div>
                    <button className="col-md-12 apply-btn mt-2" onClick={() => loadFormValueByFilter()}>Apply</button>
                </div>
                <div className="row">
                    {
                        loadingView ?
                            <div className="mt-2" style={{ padding: 0 }}>
                                <TableLoading />
                            </div>
                            :
                            viewData != null &&
                            <>
                                <div className="mt-2">
                                    <label className="col-md-1">Search</label>
                                    <select id="selectRefNumber" className="col-md-3" onChange={(x) => onChangeRefNumberFilter(x.target.value)}>
                                        <option value="">Select a KPI Ref Number</option>
                                        {
                                            refNumberSelect.length > 0 ?
                                                refNumberSelect.map(item => {
                                                    return <option key={`refNumber_${item.kpiRefNumber}`} value={item.kpiRefNumber}>{item.kpiRefNumber}</option>
                                                })
                                                :
                                                <></>
                                        }
                                    </select>
                                </div>
                                <Table className="mt-2">
                                    <thead>
                                        <tr>
                                            <th width="30%">Information</th>
                                            <th width="24%" className="text-center">{selectedVersionNumberOne}</th>
                                            <th width="2%" className="text-center"><FaArrowRight size={15} /></th>
                                            <th width="24%" className="text-center">{selectedVersionNumberTwo}</th>
                                            <th width="20%" className="text-center"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td width="30%">Date Last Modification</td>
                                            <td width="24%" className="text-center">{viewData.lastModificationDateOne.split(' ')[0]}</td>
                                            <td width="2%" className="text-center"><FaArrowRight size={15} /></td>
                                            <td width="24%" className="text-center">{viewData.lastModificationDateTwo.split(' ')[0]}</td>
                                            <th width="20%" className="text-center"></th>
                                        </tr>
                                        <tr>
                                            <td width="30%">User Last Modification</td>
                                            <td width="24%" className="text-center">{viewData.lastModificationUserOne}</td>
                                            <td width="2%" className="text-center"><FaArrowRight size={15} /></td>
                                            <td width="24%" className="text-center">{viewData.lastModificationUserTwo}</td>
                                            <th width="20%" className="text-center"></th>
                                        </tr>
                                        <tr>
                                            <td width="30%">Status</td>
                                            <td width="24%" className="text-center">{viewData.statusOne}</td>
                                            <td width="2%" className="text-center"><FaArrowRight size={15} /></td>
                                            <td width="24%" className="text-center">{viewData.statusTwo}</td>
                                            <th width="20%" className="text-center"></th>
                                        </tr>
                                    </tbody>
                                </Table>
                                <Table id="versionTable" className="mt-2">
                                    <thead className="sticky-top">
                                        <tr>
                                            <th width="15%">KPI</th>
                                            <th width="15%">Column</th>
                                            <th width="24%" className="text-center">{selectedVersionNumberOne}</th>
                                            <th width="2%" className="text-center"><FaArrowRight size={15} /></th>
                                            <th width="24%" className="text-center">{selectedVersionNumberTwo}</th>
                                            <th width="20%" className="text-center">Variation</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            viewData.versionComparisonList && viewData.versionComparisonList.length > 0 ?
                                                viewData.versionComparisonList.map((item, index) => {
                                                    var unitMeasure = GetTypeInput(item.unitMeasureID, item.unitMeasureType);
                                                    var yearChanged = false;
                                                    var variation = null

                                                    if (yearTarget != item.yearNumber) {
                                                        yearTarget = item.yearNumber;
                                                        yearChanged = true;
                                                    }

                                                    if (unitMeasure && (unitMeasure.inputType == "decimal" || unitMeasure.inputType == "number")) {
                                                        variation = item.valueTwo - item.valueOne
                                                    }

                                                    return <>
                                                        {
                                                            selectedRefNumber == null && selectedOperation == 4 && yearChanged && //Glidepath
                                                            <tr key={`data_subSectionKPI_${item.kpiRefNumber}_${index}`} className="sticky-top">
                                                                <td width="50%">Year</td>
                                                                <td width="24%" className="text-center">
                                                                    {
                                                                        <span className={`${item.valueChanged ? "value-change" : ""}`}>
                                                                            {item.yearNumber}
                                                                        </span>
                                                                    }
                                                                </td>
                                                                <td width="2%" className="text-center"><FaArrowRight size={15} className={`${item.valueChanged ? "value-change" : ""}`} /></td>
                                                                <td width="24%" className="text-center">
                                                                    {
                                                                        <span className={`${item.valueChanged ? "value-change" : ""}`}>
                                                                            {item.yearNumber}
                                                                        </span>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        }
                                                        <tr key={`data_subSectionKPI_${item.kpiRefNumber}_${index}`}
                                                            className={`${(selectedRefNumber == null || selectedRefNumber == "") && (item.kpiRefNumber == "1103" || item.kpiRefNumber == "1201" || item.kpiRefNumber == "2371" || item.kpiRefNumber == "8103" || item.kpiRefNumber == "8134") ? "sticky-top" : ""} ${item.collapse ? "shadow-box" : ""}`}
                                                        >
                                                            {
                                                                selectedRefNumber != null && selectedRefNumber != "" && selectedRefNumber != "1103" && (item.tobaccoTypeNameOne || item.tobaccoTypeNameTwo) ?
                                                                    <td width="15%">
                                                                        <span style={{ marginRight: "2px" }} className="badge">{item.tobaccoTypeNameOne ?? "..."}</span>
                                                                        <FaArrowRight style={{ marginRight: "2px" }} size={10} />
                                                                        <span style={{ marginRight: "2px" }} className="badge">{item.tobaccoTypeNameTwo ?? "..."}</span>
                                                                        <span>({item.kpiRefNumber}) {item.kpiDescription}</span>
                                                                    </td>
                                                                    :
                                                                    selectedRefNumber != null && selectedRefNumber != "" && selectedRefNumber != "2371" && (item.countryNameOne || item.countryNameTwo) ?
                                                                        <td width="15%">
                                                                            <span style={{ marginRight: "2px" }} className="badge">{item.countryNameOne ?? "..."}</span>
                                                                            <FaArrowRight style={{ marginRight: "2px" }} size={10} />
                                                                            <span style={{ marginRight: "2px" }} className="badge">{item.countryNameTwo ?? "..."}</span>
                                                                            <span>({item.kpiRefNumber}) {item.kpiDescription}</span>
                                                                        </td>
                                                                        :
                                                                        (item.kpiRefNumber == "8103" || item.kpiRefNumber == "8104" || item.kpiRefNumber == "8105" || item.kpiRefNumber == "8106"
                                                                            || item.kpiRefNumber == "8107" || item.kpiRefNumber == "8108" || item.kpiRefNumber == "8109" || item.kpiRefNumber == "8215" || item.kpiRefNumber == "8216") && (item.fuelTypeNameOne || item.fuelTypeNameTwo) ?
                                                                            <td width="15%">
                                                                                <span style={{ marginRight: "2px" }} className="badge">{item.fuelTypeNameOne != null ? (item.fuelTypeNameOne ?? "None") + " - " + (item.curingBarnNameOne ?? "None") : "..."}</span>
                                                                                <FaArrowRight style={{ marginRight: "2px" }} size={10} />
                                                                                <span style={{ marginRight: "2px" }} className="badge">{item.fuelTypeNameTwo != null ? (item.fuelTypeNameTwo ?? "None") + " - " + (item.curingBarnNameTwo ?? "None") : "..."}</span>
                                                                                <span>({item.kpiRefNumber}) {item.kpiDescription}</span>
                                                                            </td>
                                                                            :
                                                                            (item.kpiRefNumber == "8134" || item.kpiRefNumber == "8135" || item.kpiRefNumber == "8136") && (item.fuelTypeNameOne || item.fuelTypeNameTwo) ?
                                                                                <td width="15%">
                                                                                    <span style={{ marginRight: "2px" }} className="badge">{item.fuelTypeNameOne != null ? (item.fuelTypeNameOne ?? "None") : "..."}</span>
                                                                                    <FaArrowRight style={{ marginRight: "2px" }} size={10} />
                                                                                    <span style={{ marginRight: "2px" }} className="badge">{item.fuelTypeNameTwo != null ? (item.fuelTypeNameTwo ?? "None") : "..."}</span>
                                                                                    <span>({item.kpiRefNumber}) {item.kpiDescription}</span>
                                                                                </td>
                                                                                :
                                                                                (item.kpiRefNumber == "8203" || item.kpiRefNumber == "8204" || item.kpiRefNumber == "8205" || item.kpiRefNumber == "8206" || item.kpiRefNumber == "8207") && (item.fertilizerNameOne) ?
                                                                                    <td width="15%">
                                                                                        <span style={{ marginRight: "2px" }} className="badge">{item.fertilizerNameTwo ?? "..."}</span>
                                                                                        <span>({item.kpiRefNumber}) {item.kpiDescription}</span>
                                                                                    </td>
                                                                                    :
                                                                                    selectedRefNumber != null && selectedRefNumber != "" && item.yearNumber != null ?
                                                                                        <td width="15%">
                                                                                            <span style={{ marginRight: "2px" }} className="badge">{item.yearNumber}</span>
                                                                                            <span>({item.kpiRefNumber}) {item.kpiDescription}</span>
                                                                                        </td>
                                                                                        :
                                                                                        <td width="15%">({item.kpiRefNumber}) {item.kpiDescription}</td>
                                                            }
                                                            <td width="15%">{item.titleOfColumn}</td>
                                                            <td width="24%" className="text-center">
                                                                {
                                                                    item.unitMeasureType == "Text" ?
                                                                        <span className={`${item.valueChanged ? "value-change" : ""}`}>
                                                                            {item.kpiRefNumber == "1103" ?
                                                                                item.tobaccoTypeNameOne
                                                                                :
                                                                                item.kpiRefNumber == "2371" ?
                                                                                    item.countryNameOne
                                                                                    :
                                                                                    item.kpiRefNumber == "8103" || item.kpiRefNumber == "8134" ?
                                                                                        item.fuelTypeNameOne
                                                                                        :
                                                                                        item.kpiRefNumber == "8215" ?
                                                                                            item.curingBarnNameOne
                                                                                            :
                                                                                            item.valueOne
                                                                            }
                                                                        </span>
                                                                        :
                                                                        <NumberFormat
                                                                            thousandsGroupStyle="thousand"
                                                                            value={item.valueOne}
                                                                            decimalSeparator={unitMeasure.decimalSeparator != "" ? unitMeasure.decimalSeparator : "."}
                                                                            decimalScale={unitMeasure.decimalPrecision}
                                                                            suffix={item.valueOne != "" ? unitMeasure.suffix : ""}
                                                                            displayType="input"
                                                                            type="text"
                                                                            disabled
                                                                            className={`read-only ${item.valueChanged ? "value-change" : ""}`}
                                                                            thousandSeparator={true}
                                                                            allowNegative={true}
                                                                            allowLeadingZeros={false}
                                                                            allowEmptyFormatting={true}
                                                                        />
                                                                }
                                                            </td>
                                                            <td width="2%" className="text-center"><FaArrowRight size={15} className={`${item.valueChanged ? "value-change" : ""}`} /></td>
                                                            <td width="24%" className="text-center">
                                                                {
                                                                    item.unitMeasureType == "Text" ?
                                                                        <span className={`${item.valueChanged ? "value-change" : ""}`}>
                                                                            {
                                                                                item.kpiRefNumber == "1103" ?
                                                                                    item.tobaccoTypeNameTwo
                                                                                    :
                                                                                    item.kpiRefNumber == "2371" ?
                                                                                        item.countryNameTwo
                                                                                        :
                                                                                        item.kpiRefNumber == "8103" || item.kpiRefNumber == "8134" ?
                                                                                            item.fuelTypeNameTwo
                                                                                            :
                                                                                            item.kpiRefNumber == "8215" ?
                                                                                                item.curingBarnNameTwo
                                                                                                :
                                                                                                item.valueTwo
                                                                            }
                                                                        </span>
                                                                        :
                                                                        <NumberFormat
                                                                            thousandsGroupStyle="thousand"
                                                                            value={item.valueTwo}
                                                                            decimalSeparator={unitMeasure.decimalSeparator != "" ? unitMeasure.decimalSeparator : "."}
                                                                            decimalScale={unitMeasure.decimalPrecision}
                                                                            suffix={item.valueTwo != "" ? unitMeasure.suffix : ""}
                                                                            displayType="input"
                                                                            type="text"
                                                                            disabled
                                                                            className={`read-only ${item.valueChanged ? "value-change" : ""}`}
                                                                            thousandSeparator={true}
                                                                            allowNegative={true}
                                                                            allowLeadingZeros={false}
                                                                            allowEmptyFormatting={true}
                                                                        />
                                                                }

                                                            </td>

                                                            <td width="20%" className="text-center">

                                                                {
                                                                    variation ?
                                                                        variation > 0 ?
                                                                            <>
                                                                                <FaArrowUp style={{ marginLeft: "auto" }} color='#4DAE49' className='left' size={12}></FaArrowUp>
                                                                                <NumberFormat
                                                                                    thousandsGroupStyle="thousand"
                                                                                    value={variation}
                                                                                    decimalSeparator={unitMeasure.decimalSeparator != "" ? unitMeasure.decimalSeparator : "."}
                                                                                    decimalScale={2}
                                                                                    suffix={variation != "" ? unitMeasure.suffix : ""}
                                                                                    displayType="input"
                                                                                    type="text"
                                                                                    disabled
                                                                                    className={`read-only-variation value-change`}
                                                                                    style={{ display: "inline-block", color: variation > 0 ? "#4DAE49" : "red" }}
                                                                                    thousandSeparator={true}
                                                                                    allowNegative={true}
                                                                                    allowLeadingZeros={false}
                                                                                    allowEmptyFormatting={true}
                                                                                />
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <FaArrowDown color='red' className='left' size={12}></FaArrowDown>
                                                                                <NumberFormat
                                                                                    thousandsGroupStyle="thousand"
                                                                                    value={variation}
                                                                                    decimalSeparator={unitMeasure.decimalSeparator != "" ? unitMeasure.decimalSeparator : "."}
                                                                                    decimalScale={2}
                                                                                    suffix={variation != "" ? unitMeasure.suffix : ""}
                                                                                    displayType="input"
                                                                                    type="text"
                                                                                    disabled
                                                                                    className={`read-only-variation value-change`}
                                                                                    style={{ display: "inline-block", color: variation > 0 ? "#4DAE49" : "red" }}
                                                                                    thousandSeparator={true}
                                                                                    allowNegative={true}
                                                                                    allowLeadingZeros={false}
                                                                                    allowEmptyFormatting={true}
                                                                                />
                                                                            </>
                                                                        : <></>
                                                                }

                                                                {
                                                                    (item.commentOne || item.commentTwo || item.fileOne || item.fileTwo) ?
                                                                        item.collapsed ?
                                                                            <FaAngleUp style={{ display: "inline-block" }} size={18} className={`${item.fileChanged || item.commentChanged ? "value-change" : ""}`} onClick={() => collapseFunction(false, index)} />
                                                                            :
                                                                            <FaAngleDown style={{ display: "inline-block" }} size={18} className={`${item.fileChanged || item.commentChanged ? "value-change" : ""}`} onClick={() => collapseFunction(true, index)} />
                                                                        :
                                                                        <></>
                                                                }
                                                            </td>

                                                        </tr>
                                                        {
                                                            (item.commentOne != null || item.commentTwo != null) &&
                                                            <CollapseStyled in={item.collapsed}>
                                                                <tr>
                                                                    <td width="15%">Comment</td>
                                                                    <td width="15%"></td>
                                                                    <td width="24%" className="text-center">
                                                                        <span className={`${item.commentChanged ? "value-change" : ""}`}>
                                                                            {item.commentOne}
                                                                        </span>
                                                                    </td>
                                                                    <td width="2%" className="text-center">
                                                                        <FaArrowRight size={15} className={`${item.commentChanged ? "value-change" : ""}`} />
                                                                    </td>
                                                                    <td width="24%" className="text-center">
                                                                        <span className={`${item.commentChanged ? "value-change" : ""}`}>
                                                                            {item.commentTwo}
                                                                        </span>
                                                                    </td>
                                                                    <td width="20%"></td>
                                                                </tr>
                                                            </CollapseStyled>
                                                        }
                                                        {
                                                            (item.fileOne != null || item.fileTwo != null) &&
                                                            <CollapseStyled in={item.collapsed}>
                                                                <tr>
                                                                    <td width="15%">File</td>
                                                                    <td width="15%"></td>
                                                                    <td width="24%" className="text-center">
                                                                        {item.fileOne && <a href="#" onClick={() => getFile(item.fileOne)}>See attached file</a>}
                                                                    </td>
                                                                    <td width="2%" className="text-center">
                                                                        <FaArrowRight size={15} className={`${item.fileChanged ? "value-change" : ""}`} /></td>
                                                                    <td width="24%" className="text-center">
                                                                        {item.fileTwo && <a href="#" onClick={() => getFile(item.fileTwo)}>See attached file</a>}
                                                                    </td>
                                                                    <td width="20%"></td>
                                                                </tr>
                                                            </CollapseStyled>
                                                        }
                                                    </>
                                                })
                                                :
                                                <tr><td colSpan="4">Some selected version doesn't have data to show</td></tr>
                                        }
                                        {
                                            viewSpecificData.length > 0 &&
                                            viewSpecificData.map((item, index) => {
                                                var unitMeasure = GetTypeInput(item.unitMeasureId, item.unitMeasureType);
                                                var variation = null;

                                                if (unitMeasure && (unitMeasure.inputType == "decimal" || unitMeasure.inputType == "number")) {
                                                    variation = item.valueTwo - item.valueOne
                                                }


                                                return <tr key={`view_subSectionKPI_${item.kpiRefNumber}_${index}`}>
                                                    <td width="25%">({item.kpiRefNumber}) {item.kpiDescription}</td>
                                                    <td width="25%"></td>
                                                    <td width="24%" className="text-center">
                                                        {
                                                            item.unitMeasureType == "Text" ?
                                                                <span className={`${item.valueChanged ? "value-change" : ""}`}>{item.valueOne}</span>
                                                                :
                                                                <NumberFormat
                                                                    thousandsGroupStyle="thousand"
                                                                    value={item.valueOne}
                                                                    decimalSeparator={unitMeasure.decimalSeparator != "" ? unitMeasure.decimalSeparator : "."}
                                                                    decimalScale={unitMeasure.decimalPrecision}
                                                                    suffix={item.valueOne != "" ? unitMeasure.suffix : ""}
                                                                    displayType="input"
                                                                    type="text"
                                                                    disabled
                                                                    className={`read-only ${item.valueChanged ? "value-change" : ""}`}
                                                                    thousandSeparator={true}
                                                                    allowNegative={true}
                                                                    allowLeadingZeros={false}
                                                                    allowEmptyFormatting={true}
                                                                />
                                                        }
                                                    </td>
                                                    <td width="1%" className="text-center"><FaArrowRight size={15} className={`${item.valueChanged ? "value-change" : ""}`} /></td>
                                                    <td width="24%" className="text-center">
                                                        {
                                                            item.unitMeasureType == "Text" ?
                                                                <span className={`${item.valueChanged ? "value-change" : ""}`}>{item.valueTwo}</span>
                                                                :
                                                                <NumberFormat
                                                                    thousandsGroupStyle="thousand"
                                                                    value={item.valueTwo}
                                                                    decimalSeparator={unitMeasure.decimalSeparator != "" ? unitMeasure.decimalSeparator : "."}
                                                                    decimalScale={unitMeasure.decimalPrecision}
                                                                    suffix={item.valueTwo != "" ? unitMeasure.suffix : ""}
                                                                    displayType="input"
                                                                    type="text"
                                                                    disabled
                                                                    className={`read-only ${item.valueChanged ? "value-change" : ""}`}
                                                                    thousandSeparator={true}
                                                                    allowNegative={true}
                                                                    allowLeadingZeros={false}
                                                                    allowEmptyFormatting={true}
                                                                />
                                                        }
                                                    </td>
                                                    <td width="20%" className="text-center">
                                                        {
                                                            variation ?
                                                                variation > 0 ?
                                                                    <>
                                                                        <FaArrowUp style={{ marginLeft: "auto" }} color='#4DAE49' className='left' size={12}></FaArrowUp>
                                                                        <NumberFormat
                                                                            thousandsGroupStyle="thousand"
                                                                            value={variation}
                                                                            decimalSeparator={unitMeasure.decimalSeparator != "" ? unitMeasure.decimalSeparator : "."}
                                                                            decimalScale={2}
                                                                            suffix={variation != "" ? unitMeasure.suffix : ""}
                                                                            displayType="input"
                                                                            type="text"
                                                                            disabled
                                                                            className={`read-only-variation value-change`}
                                                                            style={{ display: "inline-block", color: variation > 0 ? "#4DAE49" : "red" }}
                                                                            thousandSeparator={true}
                                                                            allowNegative={true}
                                                                            allowLeadingZeros={false}
                                                                            allowEmptyFormatting={true}
                                                                        />
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <FaArrowDown color='red' className='left' size={12}></FaArrowDown>
                                                                        <NumberFormat
                                                                            thousandsGroupStyle="thousand"
                                                                            value={variation}
                                                                            decimalSeparator={unitMeasure.decimalSeparator != "" ? unitMeasure.decimalSeparator : "."}
                                                                            decimalScale={2}
                                                                            suffix={variation != "" ? unitMeasure.suffix : ""}
                                                                            displayType="input"
                                                                            type="text"
                                                                            disabled
                                                                            className={`read-only-variation value-change`}
                                                                            style={{ display: "inline-block", color: variation > 0 ? "#4DAE49" : "red" }}
                                                                            thousandSeparator={true}
                                                                            allowNegative={true}
                                                                            allowLeadingZeros={false}
                                                                            allowEmptyFormatting={true}
                                                                        />
                                                                    </>
                                                                : <></>
                                                        }

                                                        {
                                                            (item.commentOne || item.commentTwo || item.fileOne || item.fileTwo) ?
                                                                item.collapsed ?
                                                                    <FaAngleUp style={{ display: "inline-block" }} size={18} className={`${item.fileChanged || item.commentChanged ? "value-change" : ""}`} onClick={() => collapseFunction(false, index)} />
                                                                    :
                                                                    <FaAngleDown style={{ display: "inline-block" }} size={18} className={`${item.fileChanged || item.commentChanged ? "value-change" : ""}`} onClick={() => collapseFunction(true, index)} />
                                                                :
                                                                <></>
                                                        }
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </>
                    }
                </div>
                <ModalLoading open={loadingFile} message={"Loading file..."} />
            </Container >
            :
            <Container className="container mt-4">
                <TableLoading />
            </Container>
    );
}

export default VersionComparison;
