import styled from 'styled-components';

export const TooltipText = styled.div`
  background: transparent;
  color: #102A63;
  text-align: center;
  line-height: 44px;
  border-radius: 3px;
  cursor: pointer;
  width: 15%;

  &.active{
    color: #112A61;
  }

  &:hover{
    color: #112A61;
  }

`;
export const TooltipBox = styled.div`
  position: absolute;
  top: 0;
  left: 4rem;
  visibility: hidden;
  color: #000000;
  font-size: 12px;
  background-color: #FFF;
  width: 30rem;
  padding: 8px 8px;
  border-radius: 4px;
  transition: visibility 1s opacity 1s linear;
  opacity: 0;
  left: 2rem;
  top: 1rem;

  &.sticky-header-tooltipbox{
            z-index: 5;
        }

   &.sticky-header-tooltipbox-big{ //Se o guidance a ser exibido for muito grande (>30), utiliza esta classe, que renderiza utilizando o "top:-20px";
            z-index: 5;
            top: -10px;
            width: 60rem;
            left: 19rem;
        }

        &.sticky-header-tooltipbox-giant{ //Se o guidance a ser exibido for muito grande (>30), utiliza esta classe, que renderiza utilizando o "top:-20px";
            z-index: 5;
            top: -10px;
            left: 19rem;
            width: 60rem;
        }
`;
export const TooltipCard = styled.div`
  position: relative;
 
  & ${TooltipText}:hover + ${TooltipBox} {
    visibility: visible;
    color: #000;
    background-color: #f7f7f7;
    width: 30rem;
    padding: 8px 8px;
    box-shadow: 0px 3px 6px #00000029;
    transition: visibility 1s opacity 1s linear;
    opacity: 1;
    z-index: 99;

    &.sticky-header-tooltipbox-giant{ //Se o guidance a ser exibido for muito grande (>30), utiliza esta classe, que renderiza utilizando o "top:-20px";          
            width: 60rem;            
            left: 19rem;
        }
  }

  .active + ${TooltipBox} {
    visibility: visible;
    color: #000;
    background-color: #f7f7f7;
    width: 30rem;
    padding: 8px 8px;
    box-shadow: 0px 3px 6px #00000029;
    transition: visibility 1s opacity 1s linear;
    opacity: 1;
    z-index: 99;

    &.sticky-header-tooltipbox-giant{ //Se o guidance a ser exibido for muito grande (>30), utiliza esta classe, que renderiza utilizando o "top:-20px";          
            width: 60rem;            
            left: 19rem;
        }
  }
 
`;
