import styled from 'styled-components';

export const Container = styled.div`
    button{
        border-radius: 5px;
        color: #fff;
        margin-right: 19px;
        font-size: 13px;
        cursor: pointer;
        font-weight: bold;
        display: inline-block !important;
        vertical-align: middle !important;
    }

    .back-btn{
        background-color: #B0B0BB;
        padding: 14px 80px 14px 80px;
        border: 1px solid #B0B0BB;
        cursor: no-drop;
    }

    .forward-btn{
        background-color: #112A61;
        border: 1px solid #112A61;
        padding: 14px 80px 14px 80px;
        cursor: no-drop;
    }

    .save-btn{
        background-color: transparent;
        border: 1px solid #B0B0BB;
        color: #B0B0BB;
        padding: 14px 40px 14px 40px;

        &:hover{
            border: 1px solid #FFF;
            color: #FFF;
            background-color: #B0B0BB;
        }
    }
    
    .send-btn{
        background-color: transparent;
        border: 1px solid #28a745;
        color: #28a745;
        padding: 14px 40px 14px 40px;

        &:hover{
            border: 1px solid #FFF;
            color: #FFF;
            background-color: #28a745;
        }
    }

    .approve-btn{
        background-color: transparent;
        border: 1px solid #28a745;
        color: #28a745;
        padding: 14px 40px 14px 40px;

        &:hover{
            border: 1px solid #FFF;
            color: #FFF;
            background-color: #28a745;
        }
    }

    .export-btn{
        background-color: transparent;
        border: 1px solid #102A63;
        color: #102A63;
        padding: 14px 40px 14px 40px;

        &:hover{
            border: 1px solid #FFF;
            color: #FFF;
            background-color: #102A63;
        }
    }

    .reject-btn{
        background-color: transparent;
        border: 1px solid #dc3545;
        color: #dc3545;
        padding: 14px 40px 14px 40px;

        &:hover{
            border: 1px solid #FFF;
            color: #FFF;
            background-color: #dc3545;
        }
    }

    .reject-partial-btn{
        background-color: transparent;
        border: 1px solid #B0B0BB;
        color: #B0B0BB;
        padding: 14px 40px 14px 40px;

        &:hover{
            border: 1px solid #FFF;
            color: #FFF;
            background-color: #B0B0BB;
        }
    }

    .migrate-module-btn{
        background-color: transparent;
        border: 1px solid #B0B0BB;
        color: #B0B0BB;
        padding: 14px 40px 14px 40px;

        &:hover{
            border: 1px solid #FFF;
            color: #FFF;
            background-color: #B0B0BB;
        }
    }

    button:last-child{
        margin-right: 0!important;
    }

    div:last-child{
        text-align: right;
    }

    button:hover{
        opacity: 0.8;
    }
`
