import React, { createContext, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import api, { exceptionNotificationAPI } from '../services/api';
import Swal from 'sweetalert2';
import { useMsal } from "@azure/msal-react";
import { loginRequest, msalConfig } from "../ssoConfig";
import { parse } from '@fortawesome/fontawesome-svg-core';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const { instance, accounts } = useMsal();
    const [data, setData] = useState(() => {
        const token = localStorage.getItem('@QualityApp:token');
        const user = localStorage.getItem('@QualityApp:user');
        const userRoles = localStorage.getItem('@QualityApp:userRoles');
        if (token && user) {
            api.defaults.headers.authorization = `Bearer ${token}`;
            return { token, user, userRoles };
        }

        return {};
    });

    const signIn = async ({ username, password }) => {
        const result = await api.post('/signin', {
            username,            
            password
        });

        setSignInDataOnLocalStorage(result, false);

        localStorage.setItem('@QualityApp:userName', username)

        return result;
    }

    const signOut = async (forceRedirect = false) => {
       
        sessionStorage.clear();
        localStorage.removeItem('@QualityApp:token');
        localStorage.removeItem('@QualityApp:user');
        localStorage.removeItem('@QualityApp:userRoles');
        localStorage.removeItem('@QualityApp:userName');
        localStorage.removeItem('@QualityApp:openMenu');

        setData({});

        if(forceRedirect){
            window.location.href = document.location.href.split('/')[0] + '//' + document.location.href.split('/')[2] + '/signin';
        }
    }

    const ssoRequestToken = async () => {
        var token = '';

        await instance.loginPopup(loginRequest)
        .then((response) => {
            token = response.accessToken;
        })
        .catch(e => {
            console.log(e);
        });

        return {'token': token, 'uniqueName': getUniqueNameFromToken(token)};
    }

    const ssoSignIn = async (token = '', userName = '') => {
        const result = await api.post('/sso', {
            'token':token,
            'userName': userName
        });

        setSignInDataOnLocalStorage(result);

        return result;
    }

    const validateMFA = async ({token}) => {
        const userName = localStorage.getItem('@QualityApp:userName');

        const result = await api.post('/validateMFAToken', {
            token,
            userName
        });

        setSignInDataOnLocalStorage(result);

        return result;
    }

    const skipMFA = async () => {
        const userName = localStorage.getItem('@QualityApp:userName');

        const result = await api.post('/skipMFA', {
            userName
        });

        setSignInDataOnLocalStorage(result);

        return result;
    }

    const setSignInDataOnLocalStorage = (result, showSessionExpiringWarning = true) => {
        if(result.data.response){
            const token = result.data.response.accessToken;
            const user = result.data.response.user;
            const userRoles = result.data.response.roles;

            const parsedToken = parseJwt(token);
            const tokenExpiration = new Date(1*(parsedToken.exp + "").padEnd(13,'9'));

            const warningTime = tokenExpiration.setMinutes(tokenExpiration.getMinutes() - 5) - Date.now();

            warnUserSessionExpiring(warningTime, showSessionExpiringWarning);

            sessionStorage.clear();
            localStorage.removeItem('@QualityApp:token');
            localStorage.removeItem('@QualityApp:user');
            localStorage.removeItem('@QualityApp:userRoles');
            localStorage.removeItem('@QualityApp:userName');

            localStorage.setItem('@QualityApp:token', token);
            localStorage.setItem('@QualityApp:user', JSON.stringify(user));
            localStorage.setItem('@QualityApp:userRoles', JSON.stringify(userRoles));

            api.defaults.headers.authorization = `Bearer ${token}`;

            setData({ token, user, userRoles });
        }
    }

    function getUniqueNameFromToken(token) {
        return parseJwt(token).unique_name
    }

    function parseJwt (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    
        return JSON.parse(jsonPayload);
    };

    function warnUserSessionExpiring(warningTime, showWarning) {
        clearTimeouts();
        
        setTimeout(() => {
            setTimeout(() => {
                signOut(true)
            }, 300000);

            if(showWarning){
                Swal.fire({
                    title: 'Session expiring',
                    text: "Your session is expiring in 5 minutes. Please save everything and log in again to continue using the platform.",
                    icon: 'warning',
                    showDenyButton: false,
                    confirmButtonColor: '#112A61',
                    confirmButtonText: 'Ok',
                    showCloseButton: true
                });
            }
        }, warningTime);
    }

    function clearTimeouts(){
        var id = setTimeout(function() {}, 0);

        while (id--) {
            clearTimeout(id);
        }
    }

    return (
        <AuthContext.Provider
            // value={{ user: data.user, userRoles: data.userRoles, signIn, signOut}}
            value={{ user: data.user, userRoles: data.userRoles, signIn, signOut, ssoSignIn, validateMFA, skipMFA, ssoRequestToken}}
        >
            {children}
        </AuthContext.Provider>
    );
};

export function useAuth() {
    return useContext(AuthContext);
}
