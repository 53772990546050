import React, { useEffect, useState } from 'react';

import Api, { exceptionNotificationAPI } from '../../services/api';
import FormCrud from '../../components/FormCrud';

const CompanyForm = () => {
    const [currencyTypeOptions, setCurrencyTypeOptions] = useState([]);
    const [countryOptions, setCountryOptions] = useState([]);
    const [loading, setLoading] = useState(true);


    const monthOptions = [
        { value: 1, text: "January" },
        { value: 2, text: "February" },
        { value: 3, text: "March" },
        { value: 4, text: "April" },
        { value: 5, text: "May" },
        { value: 6, text: "June" },
        { value: 7, text: "July" },
        { value: 8, text: "August" },
        { value: 9, text: "September" },
        { value: 10, text: "October" },
        { value: 11, text: "November" },
        { value: 12, text: "December" }
    ];

    const activeOptions = [
        { value: true, text: "Yes" },
        { value: false, text: "No" }
    ];

    const fields = [
        {
            label: "Name",
            name: "name",
            type: "input"
        },
        {
            label: "Short Name",
            name: "shortName",
            type: "input"
        },
        {
            label: "Country",
            name: "countryID",
            type: "select",
            options: countryOptions
        },
        {
            label: "Country Code",
            name: "countryCode",
            type: "input"
        },
        {
            label: "Region",
            name: "region",
            type: "input"
        },
        {
            label: "Currency Type",
            name: "currencyTypeID",
            type: "select",
            options: currencyTypeOptions
        },
        {
            label: "Timezone",
            name: "timeZone",
            type: "selecttimezone"
        },
        {
            label: "Is Active",
            name: "isActive",
            type: "select",
            options: activeOptions
        },        
    ]

    const loadData = async () => {
        Api.get('CurrencyType/getAll').then((result) => {
            if (result && result.data) {
                const currencyTypeOptions = result.data.response.map(item => ({ value: item.currencyTypeID, text: item.description }));

                setCurrencyTypeOptions(currencyTypeOptions);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
        Api.get('Company/getAllCountries').then((result) => {
            if (result && result.data) {
                const countryOptions = result.data.response.map(item => ({ value: item.countryID, text: item.name }));

                setCountryOptions(countryOptions);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
        setLoading(false)
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        !loading != null ?
            currencyTypeOptions.length > 0 && countryOptions.length > 0  ? <FormCrud formName={"company"} title={"Supplier"} fields={fields}/> : <></>
        : <></>
        
    );
}

export default CompanyForm;
